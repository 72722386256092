import React, {useEffect, useState} from 'react';
import {HooksCommission} from "../../components/eImzoConfig1";
import {axios2} from "../../axios";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Steps from "../../components/Steps/Steps";
import LoadingPage from "../LoadingPage/LoadingPage";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import ThirdStepIcon from "../../images/3step";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {createEMailLetter} from "../../store/actions/EMailActions";
import SignatureModal from "../../components/Modal/SignatureModal";

const EMailLetterAgreement = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation();
  const {AppLoad, signLetter, id, error, err_msg, setError, setErrMsg} = HooksCommission()
  const navigate = useNavigate()
  const {
    loading,
    emailDoc
  } = useAppSelector(state => state.email)
  const { access } = useAppSelector((state) => state.auth);

  // const dataStep2 = localStorage.getItem('dataEmail')
  const payload = localStorage.getItem('payload')

  const [loader, setLoader] = useState<boolean>(false)

  const closeErrModal = () => {
    setError(false)
    setErrMsg("")
    navigate('/emailContract')
  }

  useEffect(() => {
    AppLoad()
    // dispatch(createEMailLetter(access, payload ? JSON.parse(payload) : null))
  }, []);

  interface Ownership {
    contract: any;
    full_name: string;
    passport_number: string;
    position: string;
    pin: string;
    file: string;
  }

  interface FormData {
    contract: string | null;
    save: number;
    type: string;
    letter_number: string;
    performer_full_name: string;
    performer_phone_number: string;
    letter_date: string;
    file_pdf: any;
    action_type: string;
    owner_ships: Ownership[];  // owner_ships is an array of Ownership objects
  }

  const dataYur: FormData = {
    save: 1,
    contract : payload ? JSON.parse(payload)?.contract : "",
    type: payload ? JSON.parse(payload)?.type : 1,
    letter_number: payload ? JSON.parse(payload)?.letter_number : "",
    performer_full_name: payload ? JSON.parse(payload)?.performer_full_name : "",
    performer_phone_number: payload ? JSON.parse(payload).performer_phone_number : "",
    letter_date: payload ? JSON.parse(payload).letter_date : "",
    file_pdf: payload ? JSON.parse(payload).file_pdf : "",
    action_type: payload ? JSON.parse(payload).action_type : "",
    owner_ships: payload ? JSON.parse(payload).owner_ships : "",
  }
  

  const getSignature = async () => {
    try {
      setLoader(true)
      const response = await axios2.post(
        'e-xat/letter',
        dataYur,
        {
          headers: {Authorization: `Bearer ${access}`}
        })
      if (response?.data?.id) {
        signLetter(response?.data?.base64_file, 'e-xat/letter', payload ? JSON.parse(payload)?.contract : "", response?.data?.id)
        // localStorage.setItem('contract1', JSON.stringify(response?.data?.contract))
      } else {
        alert('Xatolik')
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  };

  return (
    <div>
      <div className={'container vps unselectable'}>
        <div className="request">
          <Steps activeStep={5}/>
          <div className="request_content letter">
            {
              loading
                ?
                <LoadingPage/>
                :
                <PublicOffer
                  title={t("contractSigning")}
                  pdfFile={`${emailDoc}`}
                />
            }
            <div className="request_content-buttons">
              <button onClick={() => {
                navigate('/EMailContract')
                localStorage.removeItem('dataEmail')
              }}
              >
                <span>{t("back")}</span>
              </button>
              <select name="S@loxiddin" id="S@loxiddin" className='sign'></select>
              <button
                disabled={(loader || id.length === 0)}
                style={{
                  opacity: (loader || id.length === 0) ? .5 : 1,
                  pointerEvents: (loader || id.length) === 0 ? 'none' : 'all',
                }}
                id={'eri_sign'}
                onClick={getSignature}
              >
                <span>{loader ? t("waitsigning") : t("signing")}</span>
                <ThirdStepIcon color="white"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      {error && <SignatureModal close={closeErrModal} text={err_msg} />}
    </div>
  );
};

export default EMailLetterAgreement;