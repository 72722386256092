export const POST_EMAIL_START = 'POST_EMAIL_START'
export const POST_EMAIL_SUCCESS = 'POST_EMAIL_SUCCESS'
export const POST_EMAIL_ERROR = 'POST_EMAIL_ERROR'
export const POST_EMAIL_LETTER_START = 'POST_EMAIL_LETTER_START'
export const POST_EMAIL_LETTER_SUCCESS = 'POST_EMAIL_LETTER_SUCCESS'
export const POST_EMAIL_LETTER_ERROR = 'POST_EMAIL_LETTER_ERROR'
export const FILE_STORE_START = 'FILE_STORE_START'
export const FILE_STORE_SUCCESS = 'FILE_STORE_SUCCESS'
export const FILE_STORE_ERROR = 'FILE_STORE_ERROR'

export const LOT_ADD_START = 'LOT_ADD_START'
export const LOT_ADD_SUCCESS = 'LOT_ADD_SUCCESS'
export const LOT_ADD_ERROR = 'LOT_ADD_ERROR'