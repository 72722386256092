// useWebSocket.ts
import { useEffect, useRef, useState } from "react";
import {useAppSelector} from "../../hooks/redux";
import {messages} from "../../store/models";
import {api_url} from "../../axios";

const useWebSocket = (roomId: string | undefined) => {
	const { access } = useAppSelector((state) => state.auth);
	const [messages, setMessages] = useState<messages[]>([]);
	const webSocketRef = useRef<WebSocket | null>(null);

	useEffect(() => {
		if (!roomId) return;

		const webSocketUrl = `wss://${api_url}/ws/chat/${roomId}/?token=${access}`;

		webSocketRef.current = new WebSocket(webSocketUrl);

		webSocketRef.current.onmessage = (event) => {
			const data = JSON.parse(event.data);
			setMessages((prevMessages) => [...prevMessages, data.message]);
		};

		webSocketRef.current.onerror = (error) => {
			console.error("WebSocket error:", error);
		};

		return () => {
			webSocketRef.current?.close();
		};
	}, [roomId, access]);

	const sendMessage = (message: string) => {
		if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
			webSocketRef.current.send(JSON.stringify({ message }));
		}
	};

	return { messages, sendMessage, setMessages };
};

export default useWebSocket;
