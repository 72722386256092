import React, {useEffect, useState} from "react";
import Steps from "../../components/Steps/Steps";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import ThirdStepIcon from "../../images/3step";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import LoadingPage from "../LoadingPage/LoadingPage";
import {axios2} from "../../axios";
import {createVpsContract} from "../../store/actions/vpsActions";
import {HooksCommission} from "../../components/eImzoConfig";
import SignatureModal from "../../components/Modal/SignatureModal";

const VpsAgreement = () => {
  const {AppLoad, sign, error, err_msg, setError, setErrMsg, id} = HooksCommission()
  const {t} = useTranslation();
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    vpsDocument,
    loading,
    vpsContractData
  } = useAppSelector(state => state.vps)
  const {userDetail} = useAppSelector((state) => state.user);
  const {access} = useAppSelector((state) => state.auth);

  const service_id = localStorage.getItem('service_id')
  const server = localStorage.getItem('server')

  const [loader, setLoader] = useState<boolean>(false)

  useEffect(() => {
    AppLoad()
    dispatch(createVpsContract(access, vpsContractData))
    window.scrollTo(0, 0)
  }, [access]);

  const date = new Date()
  const formattedDateTime = date.toISOString()

  const dataFiz: any = {
    service: JSON.parse(service_id ? service_id : ''),
    save: 1,
    contract_date: formattedDateTime,
    configuration: JSON.parse(server ? server : '[]'),
    is_back_office: false,
  }

  const dataYur: any = {
    service: JSON.parse(service_id ? service_id : ''),
    save: 1,
    contract_date: formattedDateTime,
    configuration: JSON.parse(server ? server : '[]'),
    is_back_office: false,
  }

  const closeErrModal = () => {
    setError(false)
    setErrMsg("")
    navigate(-1)
  }

  const getSignature = async () => {
    try {
      setLoader(true)
      const response = await axios2.post(
        'vps/contract-create',
        userDetail?.u_type === 'Fizik' ? dataFiz : dataYur,
        {
          headers: {Authorization: `Bearer ${access}`}
        })
      if (response?.data?.id) {
        sign(response?.data?.base64file, 'vps', response?.data?.id, undefined)
      } else return alert('Xatolik')
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  };

  return (
    <>
      <div className={'container vps'}>
        <div className="request">
          <Steps activeStep={3}/>
          <div className="request_content">
            {
              loading
                ?
                <LoadingPage/>
                :
                <PublicOffer
                  title={t("contractSigning")}
                  pdfFile={`${vpsDocument}`}
                />
            }
            <div className="request_content-buttons">
              <button onClick={() => navigate('/vpsContractInfo')}>
                <span>{t("back")}</span>
              </button>
              <select name="S@loxiddin" id="S@loxiddin" className='sign'></select>
              <button
                disabled={(loader || id.length === 0)}
                style={{
                  opacity: (loader || id.length === 0) ? .5 : 1,
                  pointerEvents: (loader || id.length === 0) ? 'none' : 'all',
                }}
                id={'eri_sign'}
                onClick={getSignature}
              >
                <span>{t("signing")}</span>
                <ThirdStepIcon color="white"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      {error && <SignatureModal close={closeErrModal} text={err_msg}/>}
    </>
  )
}

export default VpsAgreement