import React from "react";
import Arrow2 from "../../images/arrow2";
import ErrorIcon from "../../images/errorIcon";
import SuccessIcon from "../../images/successIcon";
import WarningIcon from "../../images/warningIcon";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

type Props = {
	close: () => void;
	type: "success" | "warning" | "error" | "auth" | "signed" | "application";
	navigation?: () => void;
	getSuccess?: () => void;
};

const StatusModal: React.FC<Props> = ({close, type, navigation, getSuccess}) => {
	const {t} = useTranslation();

	const navigate = useNavigate()

	const handleApplication = () => {
		close()
		navigate('/')
	}

	return (
		<div className="modalWrap">
			<div className="modalWrap_block">
				{type === "signed" && (
					<div className="modalWrap_block-status">
						<SuccessIcon/>
						<div className="modalWrap_block-status-descr">
							Ariza muvafaqiyatli imzolandi!
						</div>
						<button onClick={getSuccess}>
							<span style={{marginRight: 10}}>Keyingi</span>
							<Arrow2 color="white"/>
						</button>
					</div>
				)}
				{type === "success" && (
					<div className="modalWrap_block-status">
						<SuccessIcon/>
						<div className="modalWrap_block-status-title">
							{t("successfully")}
						</div>
						<div className="modalWrap_block-status-descr">
							{t("monitorTheStatus")}{" "}
							<span onClick={navigation}>{t("personalRoom")}</span>
						</div>
						<button onClick={close}>OK</button>
					</div>
				)}
				{type === "application" && (
					<div className="modalWrap_block-status">
						<SuccessIcon/>
						<div className="modalWrap_block-status-title">
							{t("successfully")}
						</div>
						<button onClick={handleApplication}>OK</button>
					</div>
				)}
				{type === "warning" && (
					<div className="modalWrap_block-status">
						<WarningIcon/>
						<div className="modalWrap_block-status-title">Hurmatli mijoz !</div>
						<div className="modalWrap_block-status-descr">
							Mazkur amalni bajarish uchun avtorizatsiyadan o`tishingiz lozim
						</div>
						<button onClick={close}>OK</button>
					</div>
				)}
				{type === "error" && (
					<div className="modalWrap_block-status">
						<ErrorIcon/>
						<div className="modalWrap_block-status-title">
							Amalni bajarishda xatolik
						</div>
						<div className="modalWrap_block-status-descr">
							Mazkur amalni bajarish uchun avtorizatsiyadan o`tishingiz lozim
						</div>
						<button onClick={close}>OK</button>
					</div>
				)}
				{type === "auth" && (
					<div className="modalWrap_block-status">
						<div
							style={{
								display: 'flex', justifyContent: 'end', width: "100%"
							}}
						>
							<button
								style={{
									background: 'transparent',
									border: 'none',
									borderRadius: 0,
									margin: 0,
									backdropFilter: 'none'
								}}
								onClick={close}
							>
								&#x2715;
							</button>
						</div>
						<WarningIcon/>
						{/*<ErrorIcon />*/}
						<div className="modalWrap_block-status-descr">
							{t("loginError")}
						</div>
						<button onClick={navigation}>{t("loginToSystem")}</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default StatusModal;
