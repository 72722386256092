import React, {useEffect, useState} from 'react';
import {HooksCommission} from "../../components/eImzoConfig";
import {axios2} from "../../axios";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Steps from "../../components/Steps/Steps";
import LoadingPage from "../LoadingPage/LoadingPage";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import ThirdStepIcon from "../../images/3step";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {createEMailContract} from "../../store/actions/EMailActions";
import {InterfaceEMail} from "./EMailContractInfo";
import SignatureModal from "../../components/Modal/SignatureModal";

const EMailAgreement = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation();
  const {AppLoad, sign, id, error, err_msg, setError, setErrMsg} = HooksCommission()
  const navigate = useNavigate()
  const {
    loading,
    emailDocument
  } = useAppSelector(state => state.email)
  const {access} = useAppSelector((state) => state.auth);

  const dataStep2 = localStorage.getItem('dataEmail')
  const service_id = localStorage.getItem('service_id')
  const user_count = localStorage.getItem('count')
  const payChoose = localStorage.getItem('payChoose')
  const date = new Date()
  const formattedDateTime = date.toISOString()

  const [loader, setLoader] = useState<boolean>(false)

  const closeErrModal = () => {
    setError(false)
    setErrMsg("")
    navigate('/emailContractInfo')
  }

  useEffect(() => {
    AppLoad()
    // dispatch(createEMailContract(access, dataStep2 ? JSON.parse(dataStep2) : null))
  }, []);

  const dataYur: InterfaceEMail = {
    service: service_id ? JSON.parse(service_id) : null,
    save: 1,
    contract_date: formattedDateTime,
    user_count: user_count ? JSON.parse(user_count) : null,
    pay_choose: payChoose ? JSON.parse(payChoose) : null,
    is_back_office: false,
  }

  const getSignature = async () => {
    try {
      setLoader(true)
      const response = await axios2.post(
        'e-xat/contract-create',
        dataYur,
        {
          headers: {Authorization: `Bearer ${access}`}
        })
      if (response?.data?.id) {
        sign(response?.data?.base64file, 'e-xat', response?.data?.id)
        localStorage.setItem('contract', JSON.stringify(response?.data?.id))
      } else {
        alert('Xatolik')
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  };

  return (
    <div>
      <div className={'container vps unselectable'}>
        <div className="request">
          <Steps activeStep={3}/>
          <div className="request_content">
            {
              loading
                ?
                <LoadingPage/>
                :
                <PublicOffer
                  title={t("contractSigning")}
                  pdfFile={`${emailDocument}`}
                />
            }
            <div className="request_content-buttons">
              <button onClick={() => {
                navigate('/emailContractInfo')
                localStorage.removeItem('dataEmail')
              }}
              >
                <span>{t("back")}</span>
              </button>
              <select name="S@loxiddin" id="S@loxiddin" className='sign'></select>
              <button
                disabled={(loader || id.length === 0)}
                style={{
                  opacity: (loader || id.length === 0) ? .5 : 1,
                  pointerEvents: (loader || id.length) === 0 ? 'none' : 'all',
                }}
                id={'eri_sign'}
                onClick={getSignature}
              >
                <span>{loader ? t("waitsigning") : t("signing")}</span>
                <ThirdStepIcon color="white"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      {error && <SignatureModal close={closeErrModal} text={err_msg} />}
    </div>
  );
};

export default EMailAgreement;