import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
  cleanUserDetails,
  createSavedService,
  deleteSavedService, getFizUserDetail,
  getServices,
} from "../../store/actions/contractActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import ServiceBlock from "../../components/ServiceBlock/ServiceBlock";
import SavedModal from "../../components/Modal/SavedModal";
import {clearAuthData, getMessage, logoutHandler} from "../../store/actions/authActions";
import RekModal from "../../components/Modal/RekModal";
import {ISigner} from "../../components/iSigner";
import {parseKeyList} from "../../components/iSigner/isigner";
import {HooksCommission} from "../../components/eImzoConfig";
import ChatApp from "../../components/ChatApp/ChatApp";

const MainPage: React.FC = () => {
  const iSigner = ISigner.load();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {access, response, token} = useAppSelector((state) => state.auth);
  const {services, loading} = useAppSelector(
    (state) => state.contracts
  );

  const {userDetail} = useAppSelector(state => state.user)

  const [modal, setModal] = useState<boolean>(false)


  const [keys, setKeys] = useState<any>([]);
  const [key, setKey] = useState<any>(null);

  const [disks, setDisks] = useState<string[] | null>(null)
  const [disk, setDisk] = useState<string | null>(null)

  const [isModalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => setModalOpen(false);

  const {getDisksList} = HooksCommission()

  // useEffect(() => {
  //   loadDisks().then((disks) => {
  //     setDisks(disks as string[])
  //   })
  // }, [dispatch]);
  //
  // useEffect(() => {
  //   iSigner.keyList((response: any) => {
  //     setKeys(parseKeyList(response));
  //   });
  //
  //   // iSigner.removableList((response: any) => {
  //   //   setDisks(response)
  //   //   console.log('disks', response)
  //   // })
  //
  // }, [iSigner]);

  async function loadDisks() {
    try {
      return await getDisksList();
      // return disks
    } catch (error) {
      alert(error)
      console.error("Error fetching disks:", error);
    }
  }

  const handleDownload = async () => {
    if (!disk) {
      alert("Please select a directory");
      return;
    }

    const fileUrl = 'https://dcid.unicon.uz/media/Contract/pdf/E-24-14_FAXRITDIN%20KULTURAYEVICH%20BAYPULATOV.pdf';
    const fileName = `${disk}/_downloaded_file.pdf`;

    try {
      // Fetch the file as a Blob
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();

      // Create a link to download the Blob
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  const handleSaveService = (id: number) => {
    dispatch(createSavedService({service_id: id}, access));
    setModal(true)
  };
  const handleDeleteSaveService = (id: number) => {
    dispatch(deleteSavedService(id, access));
    // window.location.reload();
  };

  useEffect(() => {
    dispatch(getServices(access));
    if (access) {
      // dispatch(getFizUserDetail(access));
      dispatch(getMessage(access, false))
    }
  }, [access, dispatch]);

  useEffect(() => {
    if (userDetail === undefined) {
      const refresh: any = localStorage.getItem('refresh_token')
      const body = {
        response,
        token,
        access,
      };
      navigate("/");
      dispatch(logoutHandler(body, access, JSON.parse(refresh ? refresh : 'null')));
      dispatch(clearAuthData(body));
      dispatch(cleanUserDetails(userDetail));
      localStorage.clear()
    }
  }, [userDetail]);

  if (loading) return <LoadingPage/>;

  return (
    <>
      {/*<RekModal/>*/}
      <div className="main">
        {window.innerWidth > 768 && <Sidebar active={location.pathname} type="main"/>}
        {/*<div className="border"></div>*/}
        <div className="main_wrap">
          {/*<div style={{margin: "20px 0"}}>*/}
          {/*  <select*/}
          {/*    name="key"*/}
          {/*    id="key"*/}
          {/*    value={key || ''}*/}
          {/*    onChange={(e) => setKey(e.target.value)}*/}
          {/*  >*/}
          {/*    <option value="">Tanlang</option>*/}
          {/*    {keys && keys?.map((item: any, index: number) => (*/}
          {/*      <option key={index}*/}
          {/*              value={item?.serial}>{item?.organization === '' ? item?.commonName : item?.organization}</option>*/}
          {/*    ))}*/}
          {/*  </select>*/}

          {/*  <select*/}
          {/*    name="disk"*/}
          {/*    id="disk"*/}
          {/*    value={disk || ''}*/}
          {/*    onChange={(e) => setDisk(e.target.value)}*/}
          {/*  >*/}
          {/*    <option value="">Tanlang</option>*/}
          {/*    {disks && disks?.map((item: any, index: number) => (*/}
          {/*      <option key={index} value={item}>{item}</option>*/}
          {/*    ))}*/}
          {/*  </select>*/}

          {/*  <button onClick={handleDownload}>click</button>*/}
          {/*</div>*/}
          <div className="main_wrap-services">
            {services && services.map((el, index: number) => (
              <ServiceBlock
                key={Math.floor(index)}
                name={el.name}
                descr={el.description}
                icon={el.image}
                favorite={el.is_saved}
                token={access}
                visible={el.group.is_visible_in_front}
                setSaved={
                  el.is_saved
                    ? () => handleDeleteSaveService(el.id)
                    : () => handleSaveService(el.id)
                }
                navigate={() => navigate(`/service/${el.id}`, {
                  state: {
                    slug: el
                  }
                })}
              />
            ))}
            {modal && <SavedModal close={() => setModal(false)}/>}
          </div>
        </div>
      </div>

      <div className="modal-circle" onClick={() => setModalOpen(true)}></div>

      <ChatApp/>

      {/*<ChatApp isOpen={isModalOpen} onClose={handleCloseModal}>*/}
      {/*  <div className="modal-content">*/}
      {/*    <p className="greeting">Salom 👋</p>*/}
      {/*    <p className="message">Sizga qanday yordam bera olaman?</p>*/}
      {/*  </div>*/}
      {/*</ChatApp>*/}
      {/* <Footer/> */}
    </>
  );
};

export default MainPage;
