import React, {useState} from "react";
import ErrorIcon from "../../images/errorIcon";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import LoadingPage from "../../pages/LoadingPage/LoadingPage";
import {Grid} from "@mui/material";
import loadingPage from "../../pages/LoadingPage/LoadingPage";
import {addLotNumber} from "../../store/actions/EMailActions";
import {getContractDetail} from "../../store/actions/contractActions";

type Props = {
	close: () => void;
	id?: string | number | null
};

type Response = {
	id?: string
	lot_number?: string
	constract_date?: string
}

const loaderPage = () => {
	return (
		<Grid
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<div className="loadingio-spinner-spinner-u17kqrniter" style={{background: 'transparent'}}>
				<div className="ldio-weqmpwzosqd">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</Grid>
	)
}

const AddLotModal: React.FC<Props> = ({close, id}) => {
	const dispatch = useAppDispatch()
	const {t} = useTranslation();
	const { access } = useAppSelector((state) => state.auth);
	const {loading} = useAppSelector((state) => state.email)
	const [lot_number, setLotNumber] = useState<string | null>(null)
	const [contract_date, setContractDate] = useState<string | null>(null)

	const today = new Date();

	const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
	const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];


	const validate = () => {
		return !lot_number || !contract_date
	}

	const onClose = () => {
		close()
		setLotNumber(null)
		setContractDate(null)
	}

	const createLot = () => {
		const data = {
			lot_number,
			contract_date: new Date(contract_date ? contract_date : '').toISOString()
		}
		dispatch(addLotNumber(data, id)).then((res: Response) => {
			if(res?.id) {
				onClose()
				alert('Muvofaqqiyatli yuborildi!')
				dispatch(getContractDetail(access, 'e-xat', id));
			} else {
				alert('Xatolik: Qaytadan urinib koring')
				onClose()
			}
		})
	}

	return (
		<div className="modalWrap">
			<div className="modalWrap_block" style={{width: '50%'}}>
				{loading ? loaderPage() : (
					<div className="modalWrap_block-status">
						<div style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px', width: '100%'}}>
							{/*<label htmlFor="count">{t("e_xat_employers")}</label>*/}
							<label htmlFor="count" style={{color: '#fff'}}>{t('lot_number')}</label>
							<input value={lot_number || ''} onChange={(e) => setLotNumber(e.target.value)} type="text"/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px', width: '100%'}}>
							{/*<label htmlFor="count">{t("e_xat_employers")}</label>*/}
							<label htmlFor="count" style={{color: '#fff'}}>{t('contract_date')}</label>
							<input value={contract_date || ''} min={firstDayOfMonth} max={lastDayOfMonth} onChange={(e) => setContractDate(e.target.value)} type="date"/>
						</div>
						<div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
							<button onClick={onClose} style={{backgroundColor: 'crimson'}}>
								<span>{t("close")}</span>
							</button>

							<button
								disabled={validate()}
								onClick={createLot}
								style={{
									opacity: validate() ? 0.5 : 1
								}}
							>
								<span>{t("save")}</span>
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AddLotModal;
