import React, {useEffect, useState} from 'react';
import {HooksCommission} from "../../components/eImzoConfig";
import {api_url, axios2} from "../../axios";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Steps from "../../components/Steps/Steps";
import LoadingPage from "../LoadingPage/LoadingPage";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import ThirdStepIcon from "../../images/3step";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {InterfaceEMail} from "./EMailContractInfo";
import SignatureModal from "../../components/Modal/SignatureModal";

const EMailAgreement = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation();
  const {AppLoad, sign, id, error, err_msg, setError, setErrMsg} = HooksCommission()
  const navigate = useNavigate()
  const {
    loading,
    emailDocument
  } = useAppSelector(state => state.email)
  const {access} = useAppSelector((state) => state.auth);

  const {state} = useLocation()

  const dataStep2 = localStorage.getItem('dataEmail')
  const service_id = localStorage.getItem('service_id')
  const user_count = localStorage.getItem('count')
  const payChoose = localStorage.getItem('payChoose')
  const date = new Date()
  const formattedDateTime = date.toISOString()

  const [loader, setLoader] = useState<boolean>(false)

  const closeErrModal = () => {
    setError(false)
    setErrMsg("")
    navigate('/emailContractInfo')
  }

  useEffect(() => {
    AppLoad()
    // dispatch(createEMailContract(access, dataStep2 ? JSON.parse(dataStep2) : null))
  }, []);

  const dataYur: InterfaceEMail = {
    service: service_id ? JSON.parse(service_id) : null,
    save: 1,
    contract_date: formattedDateTime,
    user_count: user_count ? JSON.parse(user_count) : null,
    pay_choose: payChoose ? JSON.parse(payChoose) : null,
    is_back_office: false,
  }

  const getSignature = async () => {
    try {
      setLoader(true)
      const response = await axios2.post(
        'e-xat/contract-create',
        dataYur,
        {
          headers: {Authorization: `Bearer ${access}`}
        })
      if (response?.data?.id) {
        sign(response?.data?.base64file, 'e-xat', response?.data?.id, undefined)
        localStorage.setItem('contract', JSON.stringify(response?.data?.id))
      } else {
        alert('Xatolik')
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  };

  const alreadySignature = () => {
    setLoader(true)
    try {
      sign(state?.base64file, 'e-xat', state?.id, state?.already_confirmed)
      localStorage.setItem('contract', JSON.stringify(state?.id))
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  }
  if (state?.already_confirmed) {
    return (
      <>
        <div>
          <div className={'container vps unselectable'}>
            <div className="request">
              <Steps activeStep={3}/>
              <div className="request_content">
                {
                  loading
                    ?
                    <LoadingPage/>
                    :
                    (
                      <>
                        <div className="publicOffer_title mb-4">Shartnomani Qayta imzolash</div>
                        <div className="requirement-wrapper">
                          {/*{parse(`${pdfFile}`)}*/}
                          <div
                            className="requirement-wrapper-scroll requirement-wrapper-scroll--3"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                              gap: '5px',
                              textAlign: 'center'
                            }}
                          >
                            <div
                              style={{
                                width: '49%',
                                padding: '5px',
                                border: '1px solid #000',
                                borderRadius: 10
                              }}
                            >
                              {payChoose === "1" ? t('from_budget_funds') : t('from_own_funds')}
                            </div>
                            <div
                              style={{
                                width: '49%',
                                padding: '5px',
                                border: '1px solid #000',
                                borderRadius: 10
                              }}
                            >
                              <span>"E-XAT" kalitidan foydalanuvchi xodimlar soni:</span> {user_count}
                            </div>
                            <div
                              style={{
                                width: '49%',
                                padding: '5px',
                                border: '1px solid #000',
                                borderRadius: 10
                              }}
                            >
                              <span>{t('total')}:</span> {state?.contract_cash} sum
                            </div>
                            <div
                              onClick={
                                () => window.open(
                                  `${api_url}/e-xat/contract/${state?.hashcode}`, '_blank'
                                )
                              }
                              style={{
                                textDecoration: 'underline',
                                color: 'blue',
                                cursor: 'pointer',
                                width: '49%',
                                padding: '5px',
                                border: '1px solid #000',
                                borderRadius: 10
                              }}
                            >
                              Shartnomani pdf formatida ko'rish
                            </div>
                          </div>
                        </div>
                      </>
                    )
                }
                <div
                  className="request_content-buttons"
                  style={{
                    paddingBottom: '16vw'
                  }}
                >
                  <button onClick={() => {
                    navigate('/emailContractInfo')
                    localStorage.removeItem('dataEmail')
                  }}
                  >
                    <span>{t("back")}</span>
                  </button>
                  <select name="S@loxiddin" id="S@loxiddin" className="sign"></select>
                  <button
                    disabled={(loader || id.length === 0)}
                    style={{
                      opacity: (loader || id.length === 0) ? .5 : 1,
                      pointerEvents: (loader || id.length) === 0 ? 'none' : 'all',
                    }}
                    id={'eri_sign'}
                    onClick={alreadySignature}
                  >
                    <span>{loader ? t("waitsigning") : t("signing")}</span>
                    <ThirdStepIcon color="white"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {error && <SignatureModal close={closeErrModal} text={err_msg}/>}
        </div>
      </>
    )
  } else {
    return (
      <div>
        <div className={'container vps unselectable'}>
          <div className="request">
            <Steps activeStep={3}/>
            <div className="request_content">
              {
                loading
                  ?
                  <LoadingPage/>
                  :
                  (
                    <PublicOffer
                      title={t("contractSigning")}
                      pdfFile={`${emailDocument}`}
                    />
                  )
              }
              <div className="request_content-buttons">
                <button onClick={() => {
                  navigate('/emailContractInfo')
                  localStorage.removeItem('dataEmail')
                }}
                >
                  <span>{t("back")}</span>
                </button>
                <select name="S@loxiddin" id="S@loxiddin" className="sign"></select>
                <button
                  disabled={(loader || id.length === 0)}
                  style={{
                    opacity: (loader || id.length === 0) ? .5 : 1,
                    pointerEvents: (loader || id.length) === 0 ? 'none' : 'all',
                  }}
                  id={'eri_sign'}
                  onClick={getSignature}
                >
                  <span>{loader ? t("waitsigning") : t("signing")}</span>
                  <ThirdStepIcon color="white"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        {error && <SignatureModal close={closeErrModal} text={err_msg}/>}
      </div>
    );
  }
};

export default EMailAgreement;