import React, {useEffect, useState} from 'react';
import Steps from "../../components/Steps/Steps";
import DropIcon from "../../images/dropIcon";
import {UserDetailHandler} from "../../hooks/handlers";
import {getBank, updateFizuser, updateYuruser} from "../../store/actions/accountActions";
import {useAppSelector, useAppDispatch} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import Arrow2 from "../../images/arrow2";
import {useNavigate} from "react-router-dom";
import {createEMailContract} from "../../store/actions/EMailActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import {getFizUserDetail, refreshUserDetail} from "../../store/actions/contractActions";
import SavedModal from "../../components/Modal/SavedModal";

export interface InterfaceEMail {
  service: any,
  save: number,
  contract_date: string,
  user_count: string | number | null,
  pay_choose: string | number | null,
  is_back_office: boolean,
}

const EMailContractInfo = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation();
  const {access} = useAppSelector((state) => state.auth);
  const {userDetail} = useAppSelector((state) => state.user);
  const {bankInfo} = useAppSelector((state) => state.accounts);
  
  const [stir, setStir] = useState<string | undefined>(userDetail?.tin)
  const [vatNumber, setVatNumber] = useState<string | undefined>(userDetail?.vatNumber)
  const [saveModal, setSaveModal] = useState<boolean>(false)
  const [openRek, setOpenRek] = useState<boolean>(true);
  const [openContract, setOpenContract] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    userDetail?.mob_phone_no
  );
  const [address, setAddress] = useState<string | undefined>(
    userDetail?.per_adr
  );
  const [email, setEmail] = useState<string | undefined>(userDetail?.email)
  const [lang, setLang] = useState<string | undefined>(userDetail?.lang)
  const [mfo, setMfo] = useState<string | undefined>(userDetail?.bank_mfo?.mfo);
  const [xxtut, setXxtut] = useState<string | undefined>(userDetail?.xxtut);
  const [ktut, setKtut] = useState<string | undefined>(userDetail?.ktut);
  const [ifut, setIfut] = useState<string | undefined>(userDetail?.oked);
  const [checkingAccount, setCheckingAccount] = useState<string | undefined>(
    userDetail?.paymentAccount
  );
  const [bank, setBank] = useState<string | undefined>(
    userDetail?.bank_mfo?.branch_name
  );
  const [directorName, setDirectorName] = useState<string | undefined>(
    userDetail?.director_firstname
  );
  const [directorSurname, setDirectorSurname] = useState<string | undefined>(
    userDetail?.director_lastname
  );
  const [directorMidname, setDirectorMidname] = useState<string | undefined>(
    userDetail?.director_middlename
  );
  const [position, setPosition] = useState<string | null | undefined>(
    userDetail?.position
  );
  
  const storedPayChoose = localStorage.getItem('payChoose');
  const initialPayChoose: string | null = storedPayChoose ? JSON.parse(storedPayChoose) : null;
  
  const storedCount = localStorage.getItem('count');
  const initialPayCount: string | null = storedCount ? JSON.parse(storedCount) : null;
  
  const service_id = localStorage.getItem('service_id')
  
  
  const [payChoose, setChoose] = useState<string | null>(initialPayChoose);
  const [count, setCount] = useState<string | null>(initialPayCount)
  
  const date = new Date()
  const formattedDateTime = date.toISOString()
  
  useEffect(() => {
    dispatch(getBank(access, mfo))
  }, [access]);
  
  const dataYur: InterfaceEMail = {
    service: JSON.parse(service_id ? service_id : ''),
    save: 0,
    contract_date: formattedDateTime,
    user_count: Number(count),
    pay_choose: Number(payChoose),
    is_back_office: false,
  }
  
  const valid = (e: any, setState: any) => {
    const re = /^[a-zA-Z' ]*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState(e.target.value);
    }
  };
  
  const sendMfo = () => {
    dispatch(getBank(access, mfo))
  };
  
  const validateCount = (e: any, setState: any) => {
    if (/^-?\d*$/.test(e.target.value)) {
      setState(e.target.value)
    }
  }
  
  const handleValidateYurUser = () => {
    if (!phoneNumber?.length) return true;
    if (!mfo?.length) return true;
    // if (!xxtut?.length) return true;
    // if (!ktut?.length) return true;
    if (!ifut?.length) return true;
    if (!checkingAccount?.length) return true;
    if (!bank?.length) return true;
    if (!directorName?.length) return true;
    if (!directorSurname?.length) return true;
    if (!directorMidname?.length) return true;
    if (!email?.length) return true;
    if (!position) return true;
    // if (!vatNumber?.length) return true;
  };
  
  const validation = () => {
    return (!payChoose || !count || handleValidateYurUser());
  }
  
  const createContract = () => {
    dispatch(createEMailContract(access, dataYur)).then((res: any) => {
      if (typeof res === 'string') {
        localStorage.setItem('payChoose', JSON.stringify(payChoose))
        localStorage.setItem('count', JSON.stringify(count))
        localStorage.setItem('dataEmail', JSON.stringify(dataYur))
        navigate('/emailContractAgreement')
      } else if (typeof res === 'object') {
        alert(res?.err_msg)
        navigate('/')
        localStorage.removeItem('payChoose')
        localStorage.removeItem('count')
        localStorage.removeItem('dataEmail')
      }
    })
  }
  
  const saveData = () => {
    const yurBody = {
      mob_phone_no: phoneNumber,
      address,
      xxtut,
      ktut,
      oked: ifut,
      paymentAccount: checkingAccount?.replace(/[_\s]/g, ''),
      bank_mfo: bankInfo?.id,
      director_firstname: directorName,
      director_lastname: directorSurname,
      director_middlename: directorMidname,
      email,
      lang,
      vatNumber,
    };
    const fizBody = {
      mob_phone_no: phoneNumber,
      per_adr: address,
      email,
      lang,
    };
    if (userDetail?.u_type === "Fizik") {
      dispatch(updateFizuser(access, fizBody, userDetail?.id)).then((res: any) => {
        if (res?.status === 200) {
          setSaveModal(!saveModal)
        }
      })
    }
    if (userDetail?.u_type === "Yuridik") {
      dispatch(updateYuruser(access, yurBody, userDetail?.id)).then((res: any) => {
        if (res?.status === 200) {
          setSaveModal(!saveModal)
        }
      })
    }
    setTimeout(() => {
      dispatch(getFizUserDetail(access));
    }, 2000);
  };
  
  const sendStir = () => {
    dispatch(refreshUserDetail(access)).then(() => window.location.reload())
  }
  const sendVatNumber = () => {
    dispatch(refreshUserDetail(access)).then(() => window.location.reload())
  }
  
  return (
    <>
      <div className={'container'}>
        <div className={'request request_vps'}>
          <Steps activeStep={2}/>
          
          <div className={'request_content'}>
            <div>
              <h2 className="contractInfo_title">{t("props")}</h2>
              <div className="contractInfo_drop">
                <div
                  className="contractInfo_drop-head"
                  onClick={() => setOpenRek(!openRek)}
                >
                  <div className="contractInfo_drop-head-title">{t("props")}</div>
                  <div
                    className="contractInfo_drop-head-icon"
                    style={{transform: openRek ? "rotate(-180deg)" : ""}}
                  >
                    <DropIcon/>
                  </div>
                </div>
                {openRek && (
                  <>
                    <UserDetailHandler
                      setStir={(e: any) => setStir(e.target.value)}
                      sendStir={sendStir}
                      vatNumber={vatNumber}
                      setVatNumber={(e: any) => setVatNumber(e.target.value)}
                      sendVatNumber={sendVatNumber}
                      email={email}
                      setEmail={(e: any) => setEmail(e.target.value)}
                      lang={lang}
                      setLang={(e: any) => setLang(e.target.value)}
                      sendMfo={sendMfo}
                      detail={userDetail}
                      phoneNumber={phoneNumber}
                      setPhoneNumber={(e: any) => setPhoneNumber(e.target.value)}
                      address={address}
                      setAddress={(e: any) => setAddress(e.target.value)}
                      mfo={mfo}
                      setMfo={(e: any) => setMfo(e.target.value)}
                      xxtut={xxtut}
                      setXxtut={(e: any) => setXxtut(e.target.value)}
                      ktut={ktut}
                      setKtut={(e: any) => setKtut(e.target.value)}
                      ifut={ifut}
                      setIfut={(e: any) => setIfut(e.target.value)}
                      checkingAccount={checkingAccount}
                      setCheckingAccount={(e: any) => setCheckingAccount(e.target.value)}
                      bank={bankInfo?.bank_name}
                      setBank={(e: any) => valid(e, setBank)}
                      directorName={directorName}
                      setDirectorName={(e: any) => valid(e, setDirectorName)}
                      directorSurname={directorSurname}
                      setDirectorSurname={(e: any) => valid(e, setDirectorSurname)}
                      directorMidname={directorMidname}
                      setDirectorMidname={(e: any) => valid(e, setDirectorMidname)}

                      position={position}
                      setPosition={(e: any) => valid(e, setPosition)}
                    />
                    <div className="profile_wrap-form-block-btn">
                      <button
                        className={'px-3'}
                        onClick={() => navigate('/profile')}
                        disabled={false}
                        style={{opacity: handleValidateYurUser() === undefined ? 1 : 1, whiteSpace: 'nowrap'}}
                      >
                        {t("edit")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            
            <div style={{marginTop: 20}}>
              <h2 className='contractInfo_title'>{t("contract_info")}</h2>
              <div className='contractInfo_drop'>
                <div
                  className="contractInfo_drop-head"
                  onClick={() => setOpenContract(!openContract)}
                >
                  <div className="contractInfo_drop-head-title">{t("contract_info")}</div>
                  <div
                    className="contractInfo_drop-head-icon"
                    style={{transform: openContract ? "rotate(-180deg)" : ""}}
                  >
                    <DropIcon/>
                  </div>
                </div>
                
                {openContract && (
                  <div>
                    <div className={'pay'} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                      <label htmlFor="pay">{t("select_payment_method")}</label>
                      <select className={'select'} value={payChoose || ""} onChange={(e) => setChoose(e.target.value)}
                              name="pay" id="pay">
                        <option value="">{t("select")}</option>
                        <option value="1">{t("from_budget_funds")}</option>
                        <option value="2">{t("from_own_funds")}</option>
                      </select>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px'}}>
                      <label htmlFor="count">{t("e_xat_employers")}</label>
                      <input value={count || ''} onChange={(e) => validateCount(e, setCount)} type="text"/>
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      marginTop: '15px',
                      width: '20%',
                      alignItems: 'end',
                      marginLeft: 'auto'
                    }}>
                      <label htmlFor="count">{t("total")}</label>
                      <input value={`${740925 * Number(count)} ${t("uzs")}` || ''} disabled={true} type="text"/>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="request_content-buttons" style={{padding: '10px 0'}}>
              <button onClick={() => navigate('/e-xat')}
              >
                <span>{t("back")}</span>
              </button>
              <button
                disabled={validation()}
                style={{opacity: validation() ? .5 : 1}}
                onClick={createContract}
              >
                <span>{t("next")}</span>
                <Arrow2 color="white"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      {saveModal && <SavedModal close={() => setSaveModal(!saveModal)}/>}
    </>
  );
};

export default EMailContractInfo;