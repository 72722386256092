import moment from "moment";
import React, {useEffect, useState} from "react";
import SecondStepIcon from "../../images/2step";
import LegalyIcon from "../../images/legalyIcon";
import RequestIcon from "../../images/requestIcon";
import ShowReqIcon from "../../images/showReqIcon";
import {iContractDetail, iUserDetail} from "../../store/models";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import ThirdStepIcon from "../../images/3step";
import {
	rejectContract
} from "../../store/actions/contractActions";
import {useLocation, useNavigate} from "react-router-dom";
import {HooksCommission} from "../eImzoConfig";
import {handleType} from "../../store/actions/handleType";
import * as vpsActionTypes from "../../store/actionTypes/vpsActionTypes";
import SignatureModal from "../Modal/SignatureModal";
import AddLotModal from "../Modal/AddLotModal";
import DownloadIcon from "../../images/DownloadIcon";

type RowProps = {
	label: string;
	value: any;
	span?: string | undefined;
};

const RowBlock: React.FC<RowProps> = ({label, value, span}) => {
	return (
		<div className="showRequest_row-right-block-body-table-row">
			<div className="showRequest_row-right-block-body-table-row-item">
				{label}
			</div>
			<div className="showRequest_row-right-block-body-table-row-item">
				<span>{span}</span>
				{value}
			</div>
		</div>
	);
};

type Props = {
	detail: iContractDetail | null;
	userDetail: iUserDetail | null;
};

const ShowRequest: React.FC<Props> = ({detail, userDetail}) => {
	const {t} = useTranslation();
	const {AppLoad, sign, error, err_msg, setError, setErrMsg} = HooksCommission()
	const navigate = useNavigate()
	const dispatch = useAppDispatch();
	const location = useLocation();
	const {state} = location

	const {language} = useAppSelector((state) => state.language);
	const {access} = useAppSelector((state) => state.auth);
	const {change} = useAppSelector((state) => state.userService);
	const [comment, setReject] = useState("");
	const [openContractInfo, setOpenContractInfo] = useState<boolean>(false);
	const [openUserInfo, setOpenUserInfo] = useState<boolean>(false);
	const [openProcess, setOpenProcess] = useState<boolean>(false);
	const [openYurInfo, setOpenYurInfo] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false)

	const [letterOpen, setLetterOpen] = useState<boolean>(false)
	const [letterEmployee, setLetterEmployee] = useState<boolean>(false)

	const [lotModal, setLotModal] = useState<boolean>(false)

	useEffect(() => {
		AppLoad()
	}, []);

	const contract = detail?.contract.id
	const count = detail?.contract.user_count
	localStorage.setItem('contract', JSON.stringify(contract))
	localStorage.setItem('count', JSON.stringify(count))

	const getSignature = () => {
		setLoader(true)
		sign(detail?.contract?.base64file, change?.userSelectService, detail?.contract?.id, undefined)
	};

	const closeErrModal = () => {
		setError(false)
		setErrMsg("")
		setLoader(false)
	}

	const handleChange = () => {
		setIsActive(!isActive);
	};

	const handleDateFormat = (date: string | undefined) => {
		if (date) return moment(date).format("DD.MM.YYYY");
	};

	const handleRuGrammatic = (day: number) => {
		if (day === 1) return "рабочий день";
		if (day === 2) return "рабочие дни";
		if (day > 2) return "рабочих дней";
	};

	return (
		<div className="showRequest">
			<div className="showRequest_titleBlock">
				<div className="showRequest_titleBlock-date">
					{handleDateFormat(detail?.contract.contract_date)}
				</div>
				<div className="showRequest_titleBlock-title">
					{t("acceptedForConsideration")}
				</div>
			</div>
			<div className="showRequest_row">
				<div className="showRequest_row-left">
					<div className="showRequest_row-left-item">
						<div className="showRequest_row-left-item-line"/>
						<div
							className="showRequest_row-left-item-circle"
							onClick={() => setOpenContractInfo(!openContractInfo)}
						>
							<RequestIcon/>
						</div>
						<div
							className="showRequest_row-left-item-line"
							style={{height: openContractInfo ? 420 : 25}}
						/>
						<div
							className="showRequest_row-left-item-circle"
							onClick={() => setOpenUserInfo(!openUserInfo)}
						>
							<SecondStepIcon color="white" width={32} height={32}/>
						</div>
						<div
							className="showRequest_row-left-item-line"
							style={{height: openUserInfo ? 825 : 25}}
						/>
						{userDetail?.u_type === "Yuridik" && (
							<>
								<div
									className="showRequest_row-left-item-circle"
									onClick={() => setOpenYurInfo(!openYurInfo)}
								>
									<LegalyIcon/>
								</div>
								<div
									className="showRequest_row-left-item-line"
									style={{height: openYurInfo ? 720 : 25}}
								/>
							</>
						)}
						<div
							className="showRequest_row-left-item-circle"
							onClick={() => setOpenProcess(!openProcess)}
						>
							<ShowReqIcon/>
						</div>

						{detail?.signed_letter?.signed_letter?.id && (
							<>
								<div
									className="showRequest_row-left-item-line"
									style={{height: openProcess ? 900 : 25}}
								/>
								<div
									className="showRequest_row-left-item-circle"
									onClick={() => setLetterOpen(!letterOpen)}
								>
									<ShowReqIcon/>
								</div>

								<div
									className="showRequest_row-left-item-line"
									style={{height: letterOpen ? 325 : 25}}
								/>
								<div
									className="showRequest_row-left-item-circle"
									onClick={() => setLetterEmployee(!letterEmployee)}
								>
									<ShowReqIcon/>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="showRequest_row-right">
					<div
						className="showRequest_row-right-block"
						style={{height: openContractInfo ? 440 : 48}}
					>
						<div
							className="showRequest_row-right-block-head"
							onClick={() => setOpenContractInfo(!openContractInfo)}
						>
							<span>{t("application")}</span>
						</div>
						{openContractInfo && (
							<>
								<div className="showRequest_row-right-block-body">
									{/* <span>Umumiy ma'lumotlar</span> */}
									<div className="showRequest_row-right-block-body-table">
										<RowBlock
											label={t("contractNumber")}
											value={detail?.contract.contract_number}
										/>
										<RowBlock
											label={t("contractDate")}
											value={handleDateFormat(detail?.contract.contract_date)}
										/>
										<RowBlock
											label={t("contractStatus")}
											value={detail?.contract.contract_status.name || detail?.contract.contract_status}
										/>
										<RowBlock
											label={t("termOfContract")}
											value={
												detail?.contract.expiration_date
													? moment(detail?.contract.expiration_date).format('DD.MM.YYYY')
													: `31.12.${moment(
														detail?.contract.contract_date ? detail?.contract.contract_date : new Date()
													).year()}`
											}
										/>
										<RowBlock
											label={t("paymentAmount")}
											value={`${detail?.contract.contract_cash} ${t("uzs")}`}
										/>
										<RowBlock
											label={t("payed")}
											value={`${detail?.contract.payed_cash} ${t("uzs")}`}
										/>
										<RowBlock
											label={t("indebtedness")}
											value={`${detail?.contract.arrearage} ${t("uzs")}`}
										/>
									</div>
								</div>
							</>
						)}
					</div>
					<div
						className="showRequest_row-right-block"
						style={{height: openUserInfo ? 845 : 48}}
					>
						<div
							className="showRequest_row-right-block-head"
							onClick={() => setOpenUserInfo(!openUserInfo)}
						>
							<span>{t("userInfo")}</span>
						</div>
						{openUserInfo && (
							<>
								<div className="showRequest_row-right-block-body">
									<span>{t("totalInfo")}</span>
									<div className="showRequest_row-right-block-body-table">
										<RowBlock
											label={t("nsm")}
											value={detail?.client.full_name}
										/>
										<RowBlock
											label={t("birthday")}
											value={detail?.client.birth_date}
										/>
										<RowBlock label={t("stir")} value={detail?.client.tin}/>
										<RowBlock
											label={t("phoneNumber")}
											value={detail?.client.mob_phone_no}
										/>
										<RowBlock label={t("mail")} value={detail?.client.email}/>
									</div>
								</div>
								<div className="showRequest_row-right-block-body">
									<span>{t("pasportInfo")}</span>
									<div className="showRequest_row-right-block-body-table">
										<RowBlock label={t("state")} value={detail?.client.natn}/>
										<RowBlock
											label={t("birthdayPalace")}
											value={detail?.client.birth_place}
										/>
										<RowBlock
											label={t("pasportNumber")}
											value={detail?.client.pport_no}
										/>
										<RowBlock
											label={t("pasportPin")}
											value={detail?.client.pin}
										/>
										<RowBlock
											label={t("liveAddress")}
											value={detail?.client.per_adr}
										/>
										<RowBlock
											label={t("phoneNumber")}
											value={detail?.client.mob_phone_no}
										/>
										<RowBlock label={t("mail")} value={detail?.client.email}/>
									</div>
								</div>
							</>
						)}
					</div>
					{userDetail?.u_type === "Yuridik" && (
						<div
							className="showRequest_row-right-block"
							style={{height: openYurInfo ? 740 : 48}}
						>
							<div
								className="showRequest_row-right-block-head"
								onClick={() => setOpenYurInfo(!openYurInfo)}
							>
								<span>{t("yurInfo")}</span>
							</div>
							{openYurInfo && (
								<>
									<div className="showRequest_row-right-block-body">
										<span>{t("totalInfo")}</span>
										<div className="showRequest_row-right-block-body-table">
											<RowBlock
												label={t("orgName")}
												value={detail?.client.name}
											/>
											<RowBlock
												label={t("yurAddr")}
												value={detail?.client.per_adr}
											/>
											<RowBlock label={t("stir")} value={detail?.client.tin}/>
											<RowBlock
												label={t("phoneNumber")}
												value={detail?.client.mob_phone_no}
											/>
											<RowBlock
												label={t("mail")}
												value={detail?.client.email}
											/>
											<RowBlock
												label={t("mfo")}
												value={detail?.client.bank_mfo?.mfo}
											/>
											<RowBlock
												label={t("bank")}
												value={detail?.client.bank_mfo?.bank_name}
											/>
											<RowBlock
												label={`${t("xxtut")} (OKONX)`}
												value={detail?.client.xxtut}
											/>
											<RowBlock label={`${t("ktut")} (OKPO)`} value={detail?.client.ktut}/>
											<RowBlock label={`${t("ifut")} (OKED)`} value={detail?.client.oked}/>
											<RowBlock
												label={t("checkingAccount")}
												value={detail?.client.paymentAccount}
											/>
											<RowBlock
												label={t("directorFullname")}
												value={`${detail?.client.director_lastname} ${detail?.client.director_firstname} ${detail?.client.director_middlename}`}
											/>
										</div>
									</div>
								</>
							)}
						</div>
					)}
					<div className="showRequest_row-right-block">
						<div
							className="showRequest_row-right-block-head"
							onClick={() => setOpenProcess(!openProcess)}
						>
							<span>{t("reviewProcess")}</span>
						</div>
						{openProcess && (
							<>
								{detail?.participants.map((el) => (
									<div className="showRequest_row-right-block-body" key={el.id}>
										<div className="showRequest_row-right-block-body-table">
											<RowBlock
												label={el.role}
												value={el.agreement_status}
											/>
											<RowBlock
												label={t("comment")}
												value={
													el.expert_summary.comment
														? el.expert_summary.comment
														: "-"
												}
											/>
											<RowBlock
												label={t("lifeTime")}
												value={`1 ${
													language === "uz" ? "ish kuni" : handleRuGrammatic(1)
												}`}
												span={detail?.contract?.contract_date ? `${handleDateFormat(
													detail.contract.contract_date ? detail?.contract?.contract_date : ''
												)} - ${moment(detail.contract.contract_date)
													.add(1, "d")
													.format("DD.MM.YYYY")}` : '-'}
											/>
											<RowBlock
												label={`${t("concluded")}: ${
													el.userdata.userdata.role.name
												}`}
												value={el.userdata.full_name}
											/>
											<RowBlock
												label={t("phoneNumber")}
												value={el.userdata.mob_phone_no}
											/>
										</div>
									</div>
								))}
							</>
						)}
					</div>

					{detail?.signed_letter?.signed_letter?.id && (
						<>
							<div className="showRequest_row-right-block">
								<div
									className="showRequest_row-right-block-head"
									onClick={() => setLetterOpen(!letterOpen)}
								>
									<span>{t("letter")}</span>
								</div>
								{letterOpen && (
									<>
										<div className="showRequest_row-right-block-body">
											<div className="showRequest_row-right-block-body-table">
												<RowBlock
													// label={t("contractNumber")}
													label={'Xat raqami'}
													value={detail?.signed_letter?.signed_letter?.letter_number}
												/>
												<RowBlock
													// label={t("contractDate")}
													label={'Xat sanasi'}
													value={handleDateFormat(detail?.signed_letter?.signed_letter?.letter_date)}
												/>
												<RowBlock
													// label={t("contractNumber")}
													label={'Ijrochi'}
													value={detail?.signed_letter?.signed_letter?.performer_full_name}
												/>
												<RowBlock
													// label={t("contractNumber")}
													label={'Ijrochi telefon raqami'}
													value={detail?.signed_letter?.signed_letter?.performer_phone_number}
												/>
												<RowBlock
													// label={t("contractNumber")}
													label={'Xat yuklab olish'}
													value={
														<button
															onClick={() => window.open(detail?.signed_letter?.signed_letter?.file, '_blank')}
															style={{outline: 'none', border: 'none'}}
														>
															<DownloadIcon/>
														</button>
													}
												/>

												{detail?.signed_letter?.signed_letter_ownership?.map((item, index) => (
													<div key={index}>

													</div>
												))}
											</div>
										</div>
									</>
								)}
							</div>

							<div className="showRequest_row-right-block">
								<div
									className="showRequest_row-right-block-head"
									onClick={() => setLetterEmployee(!letterEmployee)}
								>
									<span>{t('employee_letter')}</span>
								</div>
								{letterEmployee && (
									<>
										{detail?.signed_letter?.signed_letter_ownership?.map((item, index) => (
											<div className="showRequest_row-right-block-body" key={index}>
												<div className="showRequest_row-right-block-body-table">
													<RowBlock
														label={t("nsm")}
														value={item?.full_name}
													/>
													<RowBlock
														label={t("pasportNumber")}
														value={item?.passport_number}
													/>
													<RowBlock
														label={t("pasportPin")}
														value={item?.pin}
													/>
													<RowBlock
														label={t("position")}
														value={item?.position}
													/>
													<RowBlock
														label={t("file")}
														value={
															<button
																onClick={() => window.open(item?.file, '_blank')}
																style={{outline: 'none', border: 'none'}}
															>
																<DownloadIcon/>
															</button>
														}
													/>
												</div>
											</div>
										))}
									</>
								)}
							</div>
						</>
					)}
				</div>
			</div>

			<div
				className={
					detail?.contract?.is_confirmed_contract === 2 && detail?.contract?.contract_number?.toLowerCase().startsWith("dm")
						? "d-flex flex-column justify-content-end mt-4"
						: "d-none"
				}
			>
				<div className="request_content-buttons">
					<button
						className="reject" style={{marginLeft: 20, marginRight: 20, lineHeight: '14px', fontSize: '14px'}}
						onClick={handleChange}
					>
						Bekor qilish
					</button>
					<select name="S@loxiddin" id="S@loxiddin" className="sign"></select>
					<button
						disabled={loader}
						style={{
							opacity: loader ? .5 : 1,
							pointerEvents: loader ? 'none' : 'all',
						}}
						id={'eri_sign'}
						onClick={getSignature}
					>
						<span>{t("signing")}</span>
						<ThirdStepIcon color="white"/>
					</button>
				</div>

				<div className={`align-items-start mt-5 ${isActive ? "d-flex" : "d-none"}`}>
          <textarea
	          value={comment}
	          onChange={e => setReject(e.target.value)}
	          className="p-3 m-1 rounded w-75"
	          placeholder="Izoh qoldiring!!! Nima uchun shartnomani bekor qilmoqchisiz?"
	          id="comment"
          />
					<button
						className="confirm_btn"
						name="reject"
						disabled={comment.length <= 6 ? true : false}
						onClick={() => {
							dispatch(rejectContract(access, comment, detail?.contract?.id === undefined ? 1 : detail?.contract?.id, 'colocation'))
							navigate(`/my-request`)
						}}
					>
						Tasdiqlayman
					</button>
				</div>
			</div>

			{(detail?.contract?.is_confirmed_contract !== 2 && detail?.contract?.contract_number?.toLowerCase().startsWith("dm")) ? (
				<div className="request_content-buttons">
					<div>
						<div className="request_content-buttons">
							<button onClick={() => {
								navigate('/my-request')
							}}>
								<span>{t("back")}</span>
							</button>
						</div>
					</div>
				</div>
			) : (
				<div className="request_content-buttons">
					<div>
						<div className="request_content-buttons">
							<button onClick={() => {
								navigate('/my-request')
							}}>
								<span>{t("back")}</span>
							</button>

							{(state?.name === 'e-xat' || state?.name === undefined) && detail?.contract.is_confirmed_contract === 3 && detail?.contract.contract_status === "Qoralama" &&
								<button onClick={() => {
									navigate('/emailContract')
								}}>
									<span>{t("continue")}</span>
								</button>
							}
							{(state?.name === 'e-xat' || state?.name === undefined) && detail?.contract?.generated_by_whom === 1 && detail?.contract.is_confirmed_contract === 1 &&
								<button onClick={() => {
									localStorage.setItem('payChoose', JSON.stringify(detail?.contract?.pay_choose))
									localStorage.setItem('count', JSON.stringify(detail?.contract?.user_count))
									navigate('/emailContractAgreement', {state: {already_confirmed: true, hashcode: detail?.contract?.hashcode, base64file: detail?.contract?.base64file, id: detail?.contract?.id, contract_cash: detail?.contract?.contract_cash}})
								}}>
									<span>{t("continue")}</span>
								</button>
							}
							{/*{(state?.name === 'e-xat' || state?.name === undefined) && detail?.contract?.pay_choose === 1 && detail?.contract.is_confirmed_contract === 4 && detail?.contract?.contract_status === "Lot raqami kutilmoqda" && (*/}
							{/*	<button*/}
							{/*		onClick={() => {*/}
							{/*			setLotModal(true)*/}
							{/*		}}*/}
							{/*	>*/}
							{/*		<span>{t('enter_lot_number')}</span>*/}
							{/*	</button>*/}
							{/*)}*/}
						</div>
					</div>
				</div>
			)}
			{error && <SignatureModal close={closeErrModal} text={err_msg}/>}
			{lotModal && <AddLotModal close={() => setLotModal(false)} id={detail?.contract?.id}/>}
		</div>
	);
};

export default ShowRequest;