import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import Steps from "../../components/Steps/Steps";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import ThirdStepIcon from "../../images/3step";
import {
  createContract,
} from "../../store/actions/contractActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import {useTranslation} from "react-i18next";
import {clearChange} from "../../store/actions/changeActions";
import {axios2} from "../../axios";
import {HooksCommission} from "../../components/eImzoConfig";
import SignatureModal from "../../components/Modal/SignatureModal";

const AgreementPage: React.FC = () => {
  const {AppLoad, sign, error, err_msg, setError, setErrMsg, id} = HooksCommission()
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  
  const { group_id, name } = location.state;
  
  const [loader, setLoader] = useState<boolean>(false)

  const {access} = useAppSelector((state) => state.auth);
  const {loading, contractDocument} = useAppSelector(
    (state) => state.contracts
  );
  
  const data = localStorage.getItem('coLocation_data')
  const service_id = localStorage.getItem('service_id')
  
  useEffect(() => {
    AppLoad()
    dispatch(createContract(access, {colocation: JSON.parse(data || ''), save: 0, service: Number(service_id)}));
    dispatch(clearChange())
  }, [access]);
  
  const closeErrModal = () => {
    setError(false)
    setErrMsg("")
    navigate('/contract-info', { state: { group_id: service_id, service_id, name: 'colocation' } })
  }

  const getSignature = async () => {
    try {
      setLoader(true)
      const response = await axios2.post(
        "colocation/contract-create",
        {colocation: JSON.parse(data || ''), save: 1, service: Number(service_id)},
        {
          headers: {Authorization: `Bearer ${access}`},
        }
      );
      if (response?.data?.id) {
        sign(response?.data?.base64file, 'colocation', response?.data?.id, undefined)
      } else {
        alert('Xatolik!')
      }
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  };
  
  return (
    <div className="container">
      <div className="request">
        <Steps activeStep={3}/>
        <div className="request_content coLocation">
          {loading ? <LoadingPage/> : <PublicOffer title={t("contractSigning")} pdfFile={`${contractDocument}`}/>}
          <div className="request_content-buttons">
            <button onClick={() => navigate('/contract-info', { state: { group_id, service_id, name } })}>
              <span>{t("back")}</span>
            </button>
            <select name="S@loxiddin" id="S@loxiddin" className='sign'></select>
            <button
              disabled={(loader || id.length === 0)}
              style={{
                opacity: (loader || id.length === 0) ? .5 : 1,
                pointerEvents: (loader || id.length === 0) ? 'none' : 'all',
              }}
              id={'eri_sign'}
              onClick={getSignature}
            >
              <span>{t("signing")}</span>
              <ThirdStepIcon color="white"/>
            </button>
          </div>
        </div>
        <div className="request_block"></div>
      </div>
      {error && <SignatureModal close={closeErrModal} text={err_msg} />}
    </div>
  );
};

export default AgreementPage;
