import React from "react";
import {iUserDetail} from "../store/models";
import InputMask from "react-input-mask";
import {useTranslation} from "react-i18next";

type Props = {
  detail: iUserDetail | null;
  saveData?: () => void;
  address: string | undefined;
  setAddress: any;
  phoneNumber: string | undefined;
  setPhoneNumber: any;
  mfo: string | undefined;
  setMfo: any;
  xxtut: string | undefined;
  setXxtut: any;
  ktut: string | undefined;
  setKtut: any;
  ifut: string | undefined;
  setIfut: any;
  checkingAccount: string | undefined;
  setCheckingAccount: any;
  bank: string | undefined;
  setBank: any;
  directorName: string | undefined;
  setDirectorName: any;
  directorSurname: string | undefined;
  setDirectorSurname: any;
  directorMidname: string | undefined;
  setDirectorMidname: any;
  sendMfo?: () => void;
  email: string | undefined;
  setEmail: any;
  lang?: string | undefined;
  setLang?: any;
  setStir?: any;
  sendStir?: () => void;
  vatNumber?: string | undefined;
  sendVatNumber?: () => void;
  setVatNumber?: any;

  setPosition?: any;
  position?: string | null
};

type InputProps = {
  label: string;
  value?: string | undefined;
  defaultValue?: string | undefined;
  onChange?: any;
  disabled?: boolean;
  type?: "input" | "mask" | "mfo" | "select" | "stir" | "qqs" | "email";
  mask?: any;
  maskChar?: any;
  sendMfo?: () => void;
  sendStir?: () => void;
  sendVatNumber?: () => void;
};

export const InputBlock: React.FC<InputProps> = ({
                                                   label,
                                                   value,
                                                   onChange,
                                                   type,
                                                   defaultValue,
                                                   disabled,
                                                   mask,
                                                   sendMfo,
                                                   sendStir,
                                                 }) => {
  const {t} = useTranslation();
  return (
    <div className="contractInfo_drop-form-block-item">
      <span className="contractInfo_drop-form-block-item-label">{label}</span>
      {type === "input" && (
        <input
          type="text"
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "no-drop" : "text",
          }}
        />
      )}
      {type === "mask" && (
        <InputMask
        disabled={disabled}
        mask={mask}
        value={value}
        onChange={onChange}
        style={{
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "no-drop" : "text",
        }}
      />
      )}
      {type === "qqs" && (
        <InputMask
        disabled={disabled}
        mask={mask}
        value={value}
        onChange={onChange}
        style={{
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "no-drop" : "text",
        }}
      />
      )}
      {type === "mfo" && (
        <div className="profile_wrap-form-block-item_wrap">
          <InputMask
            disabled={disabled}
            mask={mask}
            value={value}
            onChange={onChange}
            style={{
              opacity: disabled ? 0.5 : 1,
              cursor: disabled ? "no-drop" : "text",
            }}
          />
          {/* <button onClick={sendMfo}>{'Yangilash'}</button> */}
        </div>
      )}
      {type === "stir" && (
        <div className="profile_wrap-form-block-item_wrap">
          <InputMask
            disabled={disabled}
            mask={mask}
            value={value}
            onChange={onChange}
            style={{
              opacity: disabled ? 0.5 : 1,
              cursor: disabled ? "no-drop" : "text",
            }}
          />
          {/* <button onClick={sendStir}>{'Yangilash'}</button> */}
        </div>
      )}
      {type === 'select' && (
        <select style={{
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "no-drop" : "text",
        }}
        disabled={disabled} value={value} onChange={onChange}>
          <option value="uz">UZ</option>
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      )}
    </div>
  );
};

export const UserDetailHandler: React.FC<Props> = ({
                                                     detail,
                                                     address,
                                                     setAddress,
                                                     phoneNumber,
                                                     setPhoneNumber,
                                                     mfo,
                                                     setMfo,
                                                     xxtut,
                                                     setXxtut,
                                                     ktut,
                                                     setKtut,
                                                     ifut,
                                                     setIfut,
                                                     checkingAccount,
                                                     setCheckingAccount,
                                                     bank,
                                                     setBank,
                                                     directorName,
                                                     setDirectorName,
                                                     directorSurname,
                                                     setDirectorSurname,
                                                     directorMidname,
                                                     setDirectorMidname,
                                                     sendMfo,
                                                     email,
                                                     setEmail,
                                                     lang,
                                                     setLang,
                                                     sendStir,
                                                     setStir,
                                                     vatNumber,
                                                     sendVatNumber,
                                                     setVatNumber,

  position,
  setPosition
                                                   }) => {
  const {t} = useTranslation();
  
  return (
    <>
      {detail?.u_type === "Fizik" && (
        <div className="contractInfo_drop-form">
          <div className="contractInfo_drop-form-block">
            <InputBlock
              type="input"
              label={t("login")}
              value={detail?.user_id}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={t("name")}
              value={detail?.first_name}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={t("surname")}
              value={detail?.sur_name}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={t("midname")}
              value={detail?.mid_name}
              disabled={true}
            />
            <InputBlock
              type="select"
              label={t("lang_billing")}
              value={lang}
              onChange={setLang}
              disabled={true}
            />
          </div>
          <div className="contractInfo_drop-form-block">
            <InputBlock
              type="input"
              label={t("pasportNumber")}
              value={detail?.pport_no}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={t("pasportPin")}
              value={detail?.pin}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={t("address")}
              value={address}
              onChange={setAddress}
              disabled={true}
            />
            <InputBlock
              type="mask"
              mask="+999999999999"
              label={t("phoneNumber")}
              value={phoneNumber}
              onChange={setPhoneNumber}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={`${t("email_billing")} *`}
              value={email}
              onChange={setEmail}
              disabled={true}
            />
          </div>
        </div>
      )}
      {detail?.u_type === "Yuridik" && (
        <div className="contractInfo_drop-form">
          <div className="contractInfo_drop-form-block">
            {/* <InputBlock
              type="input"
              label={t("login")}
              value={detail?.user_id}
              disabled={true}
            /> */}
            <InputBlock
              type="stir"
              label={`${t("stir")} *`}
              value={detail.tin}
              disabled={true}
              onChange={setStir}
              sendStir={sendStir}
            />
            <InputBlock
              type="mfo"
              mask="99999"
              label={`${t("mfo")} *`}
              value={mfo}
              disabled={true}
              onChange={setMfo}
              sendMfo={sendMfo}
            />
            <InputBlock
              type="input"
              label={`${t("bank")} *`}
              value={bank}
              onChange={setBank}
              disabled={true}
            />
            <InputBlock
              type="mask"
              mask="9999 9999 9999 9999 9999 9999 999"
              label={`${t("checkingAccount")} *`}
              value={checkingAccount}
              onChange={setCheckingAccount}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={`${t("position")} *`}
              value={position || undefined}
              onChange={setPosition}
              disabled={true}
            />
            <InputBlock
              type="mask"
              mask="99999"
              label={`${t("ifut")} (OKED) *`}
              value={ifut}
              onChange={setIfut}
              disabled={true}
            />
            <InputBlock
              type="mask"
              mask="99999999"
              label={`${t("ktut")} (OKPO)`}
              value={ktut}
              onChange={setKtut}
              disabled={true}
            />
            <InputBlock
              type="mask"
              mask="99999"
              label={`${t("xxtut")} (OKONX)`}
              value={xxtut}
              onChange={setXxtut}
              disabled={true}
            />
            {/*<InputBlock*/}
            {/*  type="stir"*/}
            {/*  label={t("stir")}*/}
            {/*  value={detail?.tin}*/}
            {/*  disabled={true}*/}
            {/*  onChange={setStir}*/}
            {/*/>*/}
            <InputBlock
              type="select"
              // mask="+999999999999"
              label={t("lang_billing")}
              value={lang}
              onChange={setLang}
              disabled={true}
            />
          </div>
          <div className="contractInfo_drop-form-block">
            <InputBlock
              type="input"
              label={`${t("orgName")} *`}
              value={detail?.name}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={`${t("yurAddr")} *`}
              value={detail?.per_adr}
              disabled={true}
            />      
            <InputBlock
              type="input"
              label={`${t("directorName")} *`}
              value={directorName}
              onChange={setDirectorName}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={`${t("directorSurname")} *`}
              value={directorSurname}
              onChange={setDirectorSurname}
              disabled={true}
            />
            <InputBlock
              type="input"
              label={`${t("directorMidname")} *`}
              value={directorMidname}
              onChange={setDirectorMidname}
              disabled={true}
            />
            <InputBlock
              type="mask"
              mask="+999999999999"
              label={`${t("phoneNumber")} *`}
              value={phoneNumber}
              onChange={setPhoneNumber}
              disabled={true}
            />
            <InputBlock
              type="qqs"
              // mask=""
              label={t("vat_number")}
              value={vatNumber}
              onChange={setVatNumber}
              sendVatNumber={sendVatNumber}
              disabled={true}
            />
            <InputBlock
              type="input"
              // mask="+999999999999"
              label={`${t("email_billing")} *`}
              value={email}
              onChange={setEmail}
              disabled={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

const ProfileInputBlock: React.FC<InputProps> = ({
                                                   label,
                                                   defaultValue,
                                                   value,
                                                   onChange,
                                                   disabled,
                                                   type,
                                                   mask,
                                                   maskChar,
                                                   sendMfo,
                                                   sendStir
                                                 }) => {
  const {t} = useTranslation();
  return (
    <div className="profile_wrap-form-block-item">
      <span className="profile_wrap-form-block-item-label">{label}</span>
      {type === "input" && (
        <input
          type="text"
          defaultValue={defaultValue}
          value={value}
          onKeyPress={(event) => {
            var key = event.keyCode;
            return (key >= 65 && key <= 90) || key == 8;
          }}
          onChange={onChange}
          disabled={disabled}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "no-drop" : "text",
          }}
        />
      )}
      {type === "email" && (
        <input
          type="text"
          defaultValue={defaultValue}
          placeholder="Namuna: namuna1@gmail.com"
          value={value}
          onKeyPress={(event) => {
            var key = event.keyCode;
            return (key >= 65 && key <= 90) || key == 8;
          }}
          onChange={onChange}
          disabled={disabled}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "no-drop" : "text",
          }}
        />
      )}
      {type === "mask" && (
        <InputMask
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "no-drop" : "text",
          }}
          disabled={disabled} mask={mask} value={value} onChange={onChange} maskChar={maskChar}
        />
      )}
      {type === "qqs" && (
        <InputMask 
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "no-drop" : "text",
          }}
          disabled={disabled} mask={mask} value={value} onChange={onChange} maskChar={maskChar}
        />
      )}
      {type === "mfo" && (
        <div className="profile_wrap-form-block-item_wrap">
          <InputMask
            style={{
              opacity: disabled ? 0.5 : 1,
              cursor: disabled ? "no-drop" : "text",
            }}
            disabled={disabled} mask={mask} value={value} onChange={onChange}
          />
          <button onClick={sendMfo}>Yangilash</button>
        </div>
      )}
      {type === "stir" && (
        <div className="profile_wrap-form-block-item_wrap">
          <InputMask
            style={{
              opacity: disabled ? 0.5 : 1,
              cursor: disabled ? "no-drop" : "text",
            }}
            disabled={disabled} mask={mask} value={value} onChange={onChange}
          />
          <button onClick={sendStir}>Yangilash</button>
        </div>
      )}
      {type === 'select' && (
        <select style={{
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "no-drop" : "text",
        }}
        disabled={disabled} value={value} onChange={onChange}>
          <option value="uz">UZ</option>
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      )}
    </div>
  );
};

export const ProfileUserDetailHandler: React.FC<Props> = ({
                                                            detail,
                                                            saveData,
                                                            address,
                                                            setAddress,
                                                            phoneNumber,
                                                            setPhoneNumber,
                                                            mfo,
                                                            setMfo,
                                                            xxtut,
                                                            setXxtut,
                                                            ktut,
                                                            setKtut,
                                                            ifut,
                                                            setIfut,
                                                            checkingAccount,
                                                            setCheckingAccount,
                                                            bank,
                                                            setBank,
                                                            directorName,
                                                            setDirectorName,
                                                            directorSurname,
                                                            setDirectorSurname,
                                                            directorMidname,
                                                            setDirectorMidname,
                                                            sendMfo,
                                                            email,
                                                            setEmail,
                                                            lang,
                                                            setLang,
                                                            setStir,
                                                            sendStir,
                                                            vatNumber,
                                                            setVatNumber,
                                                            sendVatNumber,
  position,
  setPosition
                                                          }) => {
  const {t} = useTranslation();
  
  const handleValidateYurUser = () => {
    if (!phoneNumber?.length) return true;
    if (!mfo?.length) return true;
    // if (!xxtut?.length) return true;
    // if (!ktut?.length) return true;
    if (!ifut?.length) return true;
    if (!checkingAccount?.length) return true;
    if (!bank?.length) return true;
    if (!directorName?.length) return true;
    if (!directorSurname?.length) return true;
    if (!directorMidname?.length) return true;
    if (!email?.length) return true;
    // if (!vatNumber?.length) return true;
  };
  
  const handleValidateFizUser = () => {
    if (!phoneNumber?.length) return true;
    if (!address?.length) return true;
    if (!email?.length) return true;
  };
  return (
    <>
      {detail?.u_type === "Fizik" && (
        <div className="profile_wrap-form">
          <div className="profile_wrap-form-block">
            <ProfileInputBlock
              type="input"
              label={t("login")}
              value={detail?.user_id}
              disabled={true}
            />
            <ProfileInputBlock
              type="input"
              label={t("name")}
              value={detail?.first_name}
              disabled={true}
            />
            <ProfileInputBlock
              type="input"
              label={t("surname")}
              value={detail?.sur_name}
              disabled={true}
            />
            <ProfileInputBlock
              type="input"
              label={t("midname")}
              value={detail?.mid_name}
              disabled={true}
            />
            <ProfileInputBlock
              type="select"
              label={t("lang_billing")}
              value={lang}
              onChange={setLang}
            />
          </div>
          <div className="profile_wrap-form-block">
            <ProfileInputBlock
              type="input"
              label={t("pasportNumber")}
              value={detail?.pport_no}
              disabled={true}
            />
            <ProfileInputBlock
              type="input"
              label={t("pasportPin")}
              value={detail?.pin}
              disabled={true}
            />
            <ProfileInputBlock
              type="input"
              label={t("address")}
              value={address}
              onChange={setAddress}
              disabled={false}
            />
            <ProfileInputBlock
              type="mask"
              mask="+999999999999"
              label={t("phoneNumber")}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
            <ProfileInputBlock
              type="input"
              label={t("email_billing")}
              value={email}
              onChange={setEmail}
            />
            <div onClick={saveData} className="profile_wrap-form-block-btn">
              <button
                disabled={handleValidateFizUser()}
                style={{opacity: handleValidateFizUser() === true ? 0.5 : 1}}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      )}
      {detail?.u_type === "Yuridik" && (
        <div className="profile_wrap-form">
          <div className="contractInfo_drop-form-block">
            {/* <ProfileInputBlock
              type="input"
              label={t("login")}
              value={detail.user_id}
              disabled={true}
            /> */}
            <ProfileInputBlock
              type="stir"
              label={`${t("stir")} *`}
              value={detail.tin}
              disabled={true}
              onChange={setStir}
              sendStir={sendStir}
            />
             <ProfileInputBlock
              type="mfo"
              mask="99999"
              label={`${t("mfo")} *`}
              value={mfo}
              onChange={setMfo}
              sendMfo={sendMfo}
            />
            <ProfileInputBlock
              type="input"
              label={`${t("bank")} *`}
              value={bank}
              onChange={setBank}
              disabled={true}
            />
            <ProfileInputBlock
              type="mask"
              mask="9999 9999 9999 9999 9999 9999 999"
              maskChar={null}
              label={`${t("checkingAccount")} *`}
              value={checkingAccount}
              onChange={setCheckingAccount}
            />
            <ProfileInputBlock
              type="input"
              label={`${t("position")} *`}
              value={position || undefined}
              onChange={setPosition}
            />
            <ProfileInputBlock
              type="mask"
              mask="99999"
              label={`${t("ifut")} (OKED) *`}
              value={ifut}
              onChange={setIfut}
            />  
            <ProfileInputBlock
              type="mask"
              mask="99999999"
              label={`${t("ktut")} (OKPO)`}
              value={ktut}
              onChange={setKtut}
            />
            <ProfileInputBlock
              type="mask"
              mask="99999"
              label={`${t("xxtut")} (OKONX)`}
              value={xxtut}
              onChange={setXxtut}
            /> 
            <ProfileInputBlock
              type="select"
              // mask="+999999999999"
              label={t("lang_billing")}
              value={lang}
              onChange={setLang}
            />
          </div>
          <div className="contractInfo_drop-form-block">
            <ProfileInputBlock
              type="input"
              label={`${t("orgName")} *`}
              value={detail.name}
              disabled={true}
            />
            <ProfileInputBlock
              type="input"
              label={`${t("yurAddr")} *`}
              value={detail.per_adr}
              disabled={true}
            />        
            <ProfileInputBlock
              type="input"
              label={`${t("directorName")} *`}
              value={directorName}
              onChange={setDirectorName}
              disabled={false}
            />
            <ProfileInputBlock
              type="input"
              label={`${t("directorSurname")} *`}
              value={directorSurname}
              onChange={setDirectorSurname}
              disabled={false}
            />
            <ProfileInputBlock
              type="input"
              label={`${t("directorMidname")} *`}
              value={directorMidname}
              onChange={setDirectorMidname}
              disabled={false}
            />
            <ProfileInputBlock
              type="mask"
              mask="+999999999999"
              label={`${t("phoneNumber")} *`}
              value={phoneNumber}
              onChange={setPhoneNumber}
            /> 
            <ProfileInputBlock
              type="qqs"
              // mask=""
              label={t("vat_number")}
              value={vatNumber}
              onChange={setVatNumber}
              sendVatNumber={sendVatNumber}
              disabled={true}
            />
            <ProfileInputBlock
              type="email"
              // mask="+999999999999"
              label={`${t("email_billing")} *`}
              value={email}
              onChange={setEmail}
            />
            <div className="profile_wrap-form-block-btn">
              <button
                onClick={saveData}
                disabled={handleValidateYurUser()}
                style={{opacity: handleValidateYurUser() === true ? 0.5 : 1}}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
