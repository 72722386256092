import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {clearAuthData, getToken, logoutHandler, postAuth, refreshToken} from "../store/actions/authActions";
import LoadingPage from "./LoadingPage/LoadingPage";
import axios, {api_url, axios2} from "../axios";
import {cleanUserDetails} from "../store/actions/contractActions";

const InvoicePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const {search} = useLocation()
  const { response, token, access } = useAppSelector((state) => state.auth);
  const {userDetail} = useAppSelector(state => state.user)
  
  const code = search.substring(search.lastIndexOf('in/code=') + 7).split('&', 1)[0]
  
  const [authPerformed, setAuthPerformed] = useState<boolean | null>(
    JSON.parse(localStorage.getItem('authPerformed') || 'false') || false
  );
  
  const getAuth = () => {
    window.location.replace(
      `${api_url}/api/oauth/oneid-login?path=${window.location.origin}/in`
    );
    setAuthPerformed(true);
    localStorage.setItem('authPerformed', JSON.stringify(true))
  };
  
  const logout = async () => {
    try {
      const body = {
        response,
        token,
        access,
      };
      const data = await axios.post(
        "oauth/logout",
        {refresh_token: token?.refresh},
        {
          headers: {
            Authorization: `Bearer ${token?.access}`,
            "x-authentication": response?.access_token,
          },
        }
      );
      if (data.status === 205) {
        localStorage.removeItem('persist:billing-system')
        localStorage.setItem('authPerformed', JSON.stringify(false))
        dispatch(clearAuthData(body));
        dispatch(cleanUserDetails(userDetail));
        getAuth()
      }
    } catch (e) {
      localStorage.setItem('authPerformed', JSON.stringify(false))
    }
  };
  
  useEffect(() => {
    if (!authPerformed) {
      if (access) {
        console.log('hi')
        logout().then(r => r)
      } else {
        getAuth()
        setAuthPerformed(true);
        localStorage.setItem('authPerformed', JSON.stringify(true))
      }
    }
    
    // Assuming `code` and `dispatch` are defined somewhere
    dispatch(postAuth(code, `${window.location.origin}/in`));
  }, [authPerformed, code, dispatch]);
  
  useEffect(() => {
    if (response) {
      dispatch(getToken(response?.access_token));
    }
  }, [response]);
  
  useEffect(() => {
    if (token) {
      dispatch(refreshToken({refresh: token?.refresh}, navigate));
    }
  }, [token])
  
  useEffect(() => {
    if (access && authPerformed) {
      getInvoice()
    }
  }, [access]);
  
  const getInvoice = async () =>
    await axios2.post(`billing/get-invoice`, {}, {headers: {Authorization: `Bearer ${access}`}}).then((res) => {
      const blob = new Blob([res.data], {type: 'application/pdf'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice.pdf`;
      a.textContent = 'Download PDF';
      document.body.appendChild(a);
      a.click()
      document.body.removeChild(a);
      localStorage.removeItem('authPerformed')
    })
  
  return <LoadingPage />;
};

export default InvoicePage;
