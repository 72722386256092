import axios from "axios";
import i18n from "../resources/i18n";
const tin_or_pin = localStorage.getItem('tin_or_pin') ? JSON.parse(localStorage.getItem('tin_or_pin') || "") : undefined
const res = localStorage.getItem("res") ? JSON.parse(localStorage.getItem("res") || '') : undefined;

export const api_url = window.location.origin.includes('xizmatlar.unicon.uz')
  ? 'https://api2.unicon.uz'
  : 'https://dcid.unicon.uz'

export default axios.create({
    baseURL: `${api_url}/${i18n.language}/api/`,
    // baseURL: `https://dcid.unicon.uz/${i18n.language}/api/`,
    headers: { "PINORTIN": tin_or_pin }
});

export const axios2 = axios.create({
    baseURL: `${api_url}/${i18n.language}`,
    // baseURL: `https://dcid.unicon.uz/${i18n.language}`,
    headers: { "PINORTIN": tin_or_pin }
});

if (res?.access) {
    axios2.defaults.headers.common = { Authorization: `Bearer ${res?.access}` }
}