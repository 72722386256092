import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../hooks/redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useWebSocket from "../socket/useWebSocket";
import moment from "moment";
import {axios2} from "../../axios";
import ChatIcon from "../../images/chatIcon";

const ChatApp: React.FC = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {access} = useAppSelector((state) => state.auth);
	const {userDetail} = useAppSelector((state) => state.user);

	const modalBodyRef = useRef<HTMLDivElement | null>(null);
	const messagesEndRef = useRef<HTMLDivElement | null>(null);

	const [isModalOpen, setModalOpen] = useState(false);

	const {messages, sendMessage, setMessages} = useWebSocket(userDetail?.room_id);
	const [input, setInput] = useState("");

	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [maxPage, setMaxPage] = useState<number | null>(null);
	const [isAtBottom, setIsAtBottom] = useState<boolean>(false);

	useEffect(() => {
		if (userDetail?.room_id) {
			loadMessages(page);
		}
	}, [userDetail?.room_id]);

	// const handleScroll = () => {
	// 	if (!modalBodyRef.current || loading || !hasMore) return;
	//
	// 	if (modalBodyRef.current.scrollTop === 0) {
	// 		loadMessages(page + 1);
	// 	}
	// };

	const handleScroll = () => {
		if (!modalBodyRef?.current) return;

		const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;

		const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 10;
		setIsAtBottom(isScrolledToBottom);

		if (scrollTop === 0 && !loading && hasMore) {
			loadMessages(page + 1);
		}
	};

	const loadMessages = async (pageNumber: number) => {
		if (loading || (maxPage && pageNumber > maxPage)) return;

		setLoading(true);
		try {
			const res = await axios2.get(`/chat/rooms/${userDetail?.room_id}?page=${pageNumber}`);
			const { result: fetchedMessages, count } = res.data;

			if (pageNumber === 1) {
				setMaxPage(Math.ceil(count / 10));
			}

			if (fetchedMessages.length > 0) {
				setMessages((prevMessages) => [...fetchedMessages?.reverse(), ...prevMessages]);
			} else {
				setHasMore(false);
			}

			setPage(pageNumber);
		} catch (error) {
			console.error("Failed to load messages:", error);
			setHasMore(false);
		} finally {
			setLoading(false);
		}
	};

	const handleOpenModal = () => setModalOpen(!isModalOpen);
	const handleCloseModal = () => setModalOpen(false);

	const handleSendMessage = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (input.trim()) {
			sendMessage(input);
			setInput("");
			scrollToBottom()
		}
	};

	const scrollToBottom = () => {
		if (modalBodyRef.current) {
			modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
		}
	};

	return (
		<>
			<div className="modal-circle" onClick={handleOpenModal}>
				<ChatIcon/>
			</div>

			{isModalOpen && (
				<div className="modal-overlay">
					<div className="modal">
						<div className="modal-header">
							<div>
								<span>XIZMATLAR.UNICON.UZ</span>
								<a className={'phone'} href="tel:+998712080234"> <br/> +998 71 208 02 34</a>
							</div>
							<button className="close-button" onClick={handleCloseModal}>
								×
							</button>
						</div>
						<div
							className="modal-body"
							onScroll={handleScroll}
							ref={modalBodyRef}
						>
						{loading ? (
								<div className="spinner-container">
									<div className="spinner"></div>
								</div>
							) : (
							<>
								{messages.length > 0 ? (
									messages.map((msg, index) => {
										const isSent = msg.is_owner_client
										return (
											<div
												className={`chat-message ${isSent ? "sent-message" : "received-message"}`}
												key={index}
											>
												<div
													className="name">{msg.user.type === 2 ? `${msg.user.name.slice(0, 15)}...` : msg.user.name}</div>
												<div>{msg.message}</div>
												<div className="timestamp">{moment(msg.created_time).format('DD-MM-YYYY HH:MM')}</div>
											</div>
										);
									})
								) : (
									<>
										<p className="greeting">Salom 👋</p>
										<p className="message">Sizga qanday yordam bera olaman?</p>
									</>
								)}

								{messages?.length > 0 && (
									<div
										style={{
											display: !isAtBottom ? "flex" : "none"
										}}
										onClick={() => {
											messagesEndRef?.current?.scrollIntoView({behavior: "smooth"})
										}}
										className="down"
									>
										<i className="ri-arrow-down-s-line"></i>
									</div>
								)}
								<div ref={messagesEndRef}/>
							</>
						)}
						</div>

						<div className="modal-footer">
							{access ? (
								<form onSubmit={handleSendMessage} className="message-input">
									<input
										type="text"
										value={input}
										onChange={(e) => setInput(e.target.value)}
										placeholder="Type a message"
										disabled={!userDetail?.room_id}
									/>
									{input !== '' && (
										<button type="submit" disabled={!userDetail?.room_id}>
											<i className="ri-send-plane-line"></i>
										</button>
									)}
								</form>
							) : (
								<button onClick={() => navigate("/auth")} className="auth-button">
									{t("loginToSystem")}
								</button>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ChatApp;