import * as emailActionTypes from '../actionTypes/EMailActionTypes'
import {ReducerActions} from "../utilsTypes";

interface stateTypes {
  loading: boolean;
  emailDocument: null;
  emailDoc: null;
  fileStore: null;
  error: null;
}

const initialState: stateTypes = {
  loading: false,
  emailDocument: null,
  emailDoc: null,
  fileStore: null,
  error: null
}

export const emailReducer = (state = initialState, action: ReducerActions): stateTypes => {
  const {type, payload} = action
  switch (type) {
    case emailActionTypes.POST_EMAIL_START:
      return {...state, loading: true}
    case emailActionTypes.POST_EMAIL_SUCCESS:
      return {...state, loading: false, emailDocument: payload}
    case emailActionTypes.POST_EMAIL_ERROR:
      return {...state, loading: false, error: payload, emailDocument: null}
    case emailActionTypes.POST_EMAIL_LETTER_START:
      return {...state, loading: true}
    case emailActionTypes.POST_EMAIL_LETTER_SUCCESS:
      return {...state, loading: false, emailDoc: payload}
    case emailActionTypes.POST_EMAIL_LETTER_ERROR:
      return {...state, loading: false, error: payload, emailDoc: null}
    case emailActionTypes.FILE_STORE_START:
      return {...state, loading: true}
    case emailActionTypes.FILE_STORE_SUCCESS:
      return {...state, loading: false, fileStore: payload}
    case emailActionTypes.FILE_STORE_ERROR:
      return {...state, loading: false, error: payload, fileStore: null}

    case emailActionTypes.LOT_ADD_START:
      return {...state, loading: true}
    case emailActionTypes.LOT_ADD_SUCCESS:
      return {...state, loading: false}
    case emailActionTypes.LOT_ADD_ERROR:
      return {...state, loading: false}
    default:
      return state
  }
}