import {useState} from "react";
import {EIMZOClient, dates} from "./eImzoClient2";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {clearSavedContractDetails, savePkcs} from "../store/actions/contractActions";
import {useNavigate} from "react-router-dom";
import {api_url, axios2} from "../axios";

export function HooksCommission() {

	const [pkcs, setPkcs] = useState('')
	const [id, setId] = useState([])
	const [idx, setIdx] = useState([])
	const [err_msg, setErrMsg] = useState('')
	const [error, setError] = useState(false)

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const {access} = useAppSelector((state) => state.auth);
	const {userDetail} = useAppSelector((state) => state.user);
	const {savedContractDetails} = useAppSelector(
		(state) => state.contractDetails
	);

	let EIMZO_MAJOR = 3;
	let EIMZO_MINOR = 37;

	let errorCAPIWS =
		"Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.";
	let errorBrowserWS =
		"Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.";
	//   let errorUpdateApp =
	//     "ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>";
	let errorWrongPassword = "Пароль неверный.";

	let AppLoad = function () {
		EIMZOClient.API_KEYS = [
			"localhost",
			"96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B",
			"127.0.0.1",
			"A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F",
			"null",
			"E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D",
			"xizmatlar.unicon.uz",
			"1DABD86A3E612A160F8D2A7AC46A42F4885591E9F1AAE6A886BFC4259257FA2FFB0E42C2BA4BDFF16CF7383E9E4B98F4228C8B7679FD746E3EA4B802CC31AE5C",
		];
		EIMZOClient.checkVersion(
			function (major: any, minor: any) {
				let newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR;
				let installedVersion = parseInt(major) * 100 + parseInt(minor);
				if (installedVersion < newVersion) {
					//uiUpdateApp()
				} else {
					EIMZOClient.installApiKeys(function () {
						return uiLoadKeys();
					}, function (e: any, r: any) {
						if (r) {
							uiShowMessage(r);
						} else {
							wsError(e);
						}
					});
				}
			},
			function (e: any, r: any) {
				if (r) {
					uiShowMessage(r);
				} else {
					uiNotLoaded(e);
				}
			}
		);
	};

	let uiShowMessage = function (message: any) {
		alert(message);
	};

	let uiNotLoaded = function (e: any) {
		if (e) {
			wsError(e);
		} else {
			uiShowMessage(errorBrowserWS);
		}
	};

	function getDisksList() {
		return new Promise((resolve, reject) => {
			let items: any = [];
			let errors: any = [];

			EIMZOClient.listDisk(
				items,
				errors,
				() => {
					if (errors.length > 0) {
						reject(errors);
					} else {
						const flattenedItems = items.flat();
						resolve(flattenedItems);
					}
				}
			);
		});
	}

	let uiLoadKeys = function () {
		uiClearCombo();
		EIMZOClient.listAllUserKeys(
			function (o: any, i: any) {
				let itemId = "itm-" + o.serialNumber;
				// @ts-ignore
				setId([...id, o]);
				// id.push(o)
				// @ts-ignore
				idx.push(i)
				return itemId;
			},
			function (itemId: any, v: any) {
				return uiCreateItem(itemId, v);
			},
			function (items: any, firstId: any) {
				uiFillCombo(items);
				uiComboSelect(firstId);
			},
			function (e: any, r: any) {
				if (e) {
					uiShowMessage(errorCAPIWS + " : " + e);
				} else {
					uiShowMessage(r);
				}
			}
		);
	};

	let uiComboSelect = function (itm: any) {
		if (itm) {
			let el = document.getElementById(itm);
			// @ts-ignore
			el.setAttribute("selected", "true");
			// @ts-ignore
			// el.removeAttribute("disabled");
		}
	};

	let uiClearCombo = function () {
		let combo: any = document.getElementById("S@loxiddin");
		combo.length = 0;
	};

	let uiFillCombo = function (items: any) {
		// @ts-ignore
		document.getElementById("S@loxiddin").innerHTML = "";
		let combo: any = document.getElementById("S@loxiddin");
		for (let itm in items) {
			combo.append(items[itm]);
		}
	};

	let uiCreateItem = function (itmkey: any, vo: any) {
		let now = new Date();
		vo.expired = dates.compare(now, vo.validTo) > 0;
		let itm = document.createElement("option");
		itm.value = itmkey;
		if (vo.O === '') {
			itm.text = vo.CN;
		} else {
			itm.text = vo.O
		}
		if (!vo.expired) {
		} else {
			itm.style.color = "gray";
			itm.text = itm.text + " (срок истек)";
			itm.disabled = true;
		}
		itm.setAttribute("vo", JSON.stringify(vo));
		itm.setAttribute("id", itmkey);
		itm.setAttribute('name', vo.PINFL)
		// itm.setAttribute("disabled", '');
		return itm;
	};

	let wsError = function (e: any) {
		if (e) {
			uiShowMessage(errorCAPIWS + " : " + e);
		} else {
			uiShowMessage(errorBrowserWS);
		}
	};

	const sign = (data_b4: any, service: any, contract_id: any, already_sign: any | undefined) => {
		// @ts-ignore
		let itm = document.getElementById("S@loxiddin").value;
		if (itm) {
			let id = document.getElementById(itm);
			// @ts-ignore
			let vo = JSON.parse(id.getAttribute("vo"));
			const headers = {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${access}`
			}
			EIMZOClient.loadKey(
				vo,
				function (id: any) {
					EIMZOClient.createPkcs7(
						id,
						data_b4,
						null,
						function (pkcs7: any) {
							setPkcs(pkcs7)
							localStorage.setItem('pkcs7', JSON.stringify(pkcs7))
							dispatch(
								savePkcs(
									access,
									{
										pkcs7: pkcs7,
										contract_id: contract_id,
									},
									service,
								)
							).then((res: any) => {
									if (!res?.success) {
										const service_id = localStorage.getItem('service_id')
										setErrMsg(res?.err_msg)
										setError(true)
									} else {
										dispatch(clearSavedContractDetails(savedContractDetails));
										if (service === 'e-xat') {
											navigate(`/EMailContract`, {
												state: {service_id: contract_id, name: service}
											});
										} else {
											navigate(`/show-request/${contract_id}`, {
												state: {service_id: contract_id, name: service}
											});
										}
										// localStorage.removeItem('server')
										// localStorage.removeItem('service_id')
										// localStorage.removeItem('pkcs7')
										localStorage.removeItem('account_id_localStorage')
										localStorage.removeItem('payChoose')
										// localStorage.removeItem('count')
										localStorage.removeItem('dataEmail')
										localStorage.removeItem('coLocation_data')
									}
								}
							);
						},
						function (e: any, r: any) {
							if (r) {
								if (r.indexOf("BadPaddingException") !== -1) {
									axios2.delete(`${service}/reject/${contract_id}`, {headers}).then(() => {
										if (already_sign) {
											navigate('/')
											localStorage.removeItem('account_id_localStorage')
											localStorage.removeItem('payChoose')
											localStorage.removeItem('dataEmail')
											localStorage.removeItem('coLocation_data')
											localStorage.removeItem('count')
										} else {
											navigate(-1)
										}
									})
									uiShowMessage(errorWrongPassword);
								} else {
									axios2.delete(`${service}/reject/${contract_id}`, {headers}).then(() => {
										if (already_sign) {
											localStorage.removeItem('account_id_localStorage')
											localStorage.removeItem('payChoose')
											localStorage.removeItem('count')
											localStorage.removeItem('dataEmail')
											localStorage.removeItem('coLocation_data')
											navigate('/')
										} else {
											navigate(-1)
										}
									})
									uiShowMessage(r);
								}
							} else {
								uiShowMessage(errorBrowserWS);
							}
							if (e) wsError(e);
						},
						'',
						''
					);
				},
				function (e: any, r: any) {
					if (r) {
						if (r.indexOf("BadPaddingException") !== -1) {
							uiShowMessage(errorWrongPassword);
						} else {
							uiShowMessage(r);
						}
					} else {
						uiShowMessage(errorBrowserWS);
					}
					if (e) wsError(e);
				},
				''
			);
		} else
			alert("E-IMZO kalit topilmadi")
	};

	const signIn = (navigate: any) => {

		const challenge = localStorage.getItem('challenge')

		const postChallenge = async (pkcs7: any) => {
			try {
				const response = await fetch(`${api_url}/uz/api/oauth/login/eri`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({pkcs7, is_client: 1}),
				})
				const responseData = await response.json()
				localStorage.setItem('res', JSON.stringify(responseData))
				await navigate('/')
				await window.location.reload()
			} catch (e) {
				console.log(e)
			}
		}

		// @ts-ignore
		let itm = document.getElementById("S@loxiddin").value;
		if (itm) {
			let id = document.getElementById(itm);
			// @ts-ignore
			let vo = JSON.parse(id.getAttribute("vo"));
			EIMZOClient.loadKey(
				vo,
				function (id: any) {
					EIMZOClient.createPkcs7Auth(
						id,
						challenge,
						null,
						function (pkcs7: any) {
							localStorage.setItem('pkcs7', JSON.stringify(pkcs7))
							if (pkcs7) {
								postChallenge(pkcs7)
								localStorage.removeItem('challenge')
							}
							setPkcs(pkcs7);
						},
						function (e: any, r: any) {
							if (r) {
								if (r.indexOf("BadPaddingException") !== -1) {
									uiShowMessage(errorWrongPassword);
								} else {
									uiShowMessage(r);
								}
							} else {
								uiShowMessage(errorBrowserWS);
							}
							if (e) wsError(e);
						},
					);
				},
				function (e: any, r: any) {
					if (r) {
						if (r.indexOf("BadPaddingException") !== -1) {
							uiShowMessage(errorWrongPassword);
						} else {
							uiShowMessage(r);
						}
					} else {
						uiShowMessage(errorBrowserWS);
					}
					if (e) wsError(e);
				},
				''
			);
		} else
			alert("E-IMZO kalit topilmadi")
	};

	return {
		getDisksList,
		AppLoad,
		uiLoadKeys,
		sign,
		signIn,
		pkcs,
		id,
		idx,
		err_msg,
		error,
		setError,
		setErrMsg
	};
}