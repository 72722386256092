import {Dispatch} from "redux";
import {axios2} from "../../axios";
import * as contractTypes from "../actionTypes/contractsActionTypes";
import * as vpsTypes from "../actionTypes/vpsActionTypes";
import {
  iContrctSavedResponse,
  iOfferDetail,
  iUserDetail,
} from "../models";
import {handleType} from "./handleType";
import {RefreshState} from "./authActions";

export const getServices = (token: any | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      if (token) {
        const response = await axios2.get("contracts/services", {
          headers: {Authorization: `Bearer ${token}`}
        });
        dispatch(handleType(contractTypes.GET_SERVICES, response.data));
      } else {
        const response = await axios2.get("contracts/services");
        dispatch(handleType(contractTypes.GET_SERVICES, response.data));
      }
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const getGroupServices = (groupId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(
        `contracts/group-services?group_id=${groupId}`
      );
      dispatch(handleType(contractTypes.GET_GROUP_SERVICES, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const getServiceDetail = (service_id: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(
        `contracts/service-detail/${service_id}`
      );
      dispatch(handleType(contractTypes.GET_SERVICE_DETAIL, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const getServiceDetailList = (service_id: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(
        `service/${service_id}/add-list-create`
      );
      dispatch(handleType(contractTypes.GET_SERVICE_DETAIL_LIST, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_LIST_ERROR, e as Error));
    }
  };
};

export const getServiceVideoMarketingList = (service_id: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(
        `service/${service_id}/video-list-create?video_choice=2`
      );
      dispatch(handleType(contractTypes.GET_SERVICE_MARKETING_VIDEO, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.GET_SERVICE_MARKETING_VIDEO_ERROR, e as Error));
    }
  };
};

export const getServiceVideoTutorialList = (service_id: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(
        `service/${service_id}/video-list-create?video_choice=1`
      );
      dispatch(handleType(contractTypes.GET_SERVICE_TUTORIAL_VIDEO, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.GET_SERVICE_TUTORIAL_VIDEO_ERROR, e as Error));
    }
  };
};

export const getServiceReklama = (service_id: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(
        `service/${service_id}/video-list-create?video_choice=3`
      );
      dispatch(handleType(contractTypes.GET_SERVICE_REKLAMA, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.GET_SERVICE_REKLAMA_ERROR, e as Error));
    }
  };
};


export const getFizUserDetail = (token: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get("contracts/user-detail", {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.GET_USER_DETAIL, response.data));
      
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e));
      console.log(e)
    }
  };
};

export const refreshUserDetail = (token: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
    try {
      const response = await axios2.post("accounts/refresh-yuruser", {}, {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.GET_USER_DETAIL, response.data));
      window.location.reload()
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e));
    }
  };
};

export const cleanUserDetails = (data: iUserDetail | null) => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(contractTypes.CLEAN_USER_DETAILS, data));
  };
};

export const getSavedServices = (token: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get("contracts/saved-service", {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.GET_SAVED_SERVICES, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

interface BodyTypes {
  service_id: number;
}

export const createSavedService = (data: BodyTypes, token: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.post("contracts/saved-service", data, {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.CREATE_SAVED_SERVICE, response.data));
      // @ts-ignore
      dispatch(getServices(token))
    } catch (e) {
    }
  };
};

export const deleteSavedService = (service_id: number, token: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.delete(
        `contracts/delete-saved-service/${service_id}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(handleType(contractTypes.CREATE_SAVED_SERVICE, response.data));
      // @ts-ignore
      dispatch(getServices(token))
      // @ts-ignore
      dispatch(getSavedServices(token));
    } catch (e) {
    }
  };
};

export const getOfferDetail = (service_id: string | undefined, token: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get<iOfferDetail>(
        `contracts/offer-detail?service_id=${service_id}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(handleType(contractTypes.GET_OFFER_DETAIL, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const getTarifsElementsDevices = (
  group_id: number | undefined,
  token: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get<iOfferDetail>(
        `contracts/tarif-elements-devices?group_id=${group_id}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(
        handleType(contractTypes.GET_TARIFS_ELEMENTS_DEVICES, response.data)
      );
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const saveContractData = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(contractTypes.SAVE_CONTRACT, data));
  };
};

export const saveDevices = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(contractTypes.SAVE_DEVICES, data));
  };
};

export const clearSavedContractDetails = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(contractTypes.CLEAR_SAVED_CONTRACTS, data));
  };
};

export const clearDevices = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(contractTypes.CLEAR_DEVICES, data));
  };
};

export const createContract = (token: any, data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.post("colocation/contract-create", data, {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.CREATE_CONTRACT, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const createSavedContract = (token: any, data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.post<iContrctSavedResponse>(
        "contracts/contract-create",
        data,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(handleType(contractTypes.CREATE_SAVED_CONTRACT, response.data));
      console.log("actions", response.data);
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const savePkcs = (
  token: any,
  data: { pkcs7: string | null; contract_id: number | undefined },
  serv: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: any = await axios2.post(`${serv}/save-pkcs`, data, {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.SAVE_PKCS, response.data));
      if (response.status !== 200) {
        return response?.response?.data
      } else if (response.status === 200) {
        return response.data
      }
      // return response
    } catch (e) {
      console.log("e", e)
      return e
    }
  };
};

export const savePkcs7 = (
  token: any,
  data: { pkcs7: string | null; contract: number | undefined; letter: number | undefined},
  serv: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: any = await axios2.post(`${serv}/save-pkcs`, data, {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.SAVE_PKCS, response.data));
      if (response.status !== 200) {
        return response?.response?.data
      } else if (response.status === 200) {
        return response.data
      }
      // return response
    } catch (e) {
      console.log("e", e)
      return e
    }
  };
};

export const getUserContracts = (token: any, change: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(`${change}/user-contracts`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.GET_USER_CONTRACTS, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};


export const getUserContractsEkspertiza = (token: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(`expertise/user-contracts`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.GET_USER_CONTRACTS, response.data));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const getContractDetail = (
  token?: any,
  change?: any,
  contract_id?: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_LOADING, null));
      const response = await axios2.get(
        `${change}/contract-detail/${contract_id}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(handleType(contractTypes.GET_CONTRACT_DETAIL, response.data));
      dispatch(handleType(vpsTypes.CLEAR_VPS_BILLING, null))
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_ERROR, e as Error));
    }
  };
};

export const sendDevices = (token: any, body: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.post(
        "contracts/selected-tarif-devices",
        body,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(handleType(contractTypes.SEND_TARIF_DEVICES, response.data));
    } catch (e) {
    }
  };
};

export const getConnectMethods = (token: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.get("contracts/get-connect-methods", {
        headers: {Authorization: `Bearer ${token}`},
      });
      dispatch(handleType(contractTypes.GET_CONNECT_METHODS, response.data));
    } catch (e) {
    }
  };
};

export const getGroupAdmin = (service_id: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.get(
        `contracts/group-admin?service_id=${service_id}`
      );
      dispatch(handleType(contractTypes.GET_GROUP_ADMIN, response.data));
    } catch (e) {
    }
  };
};

export const getPinnedUser = (service_id: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.get(
        `contracts/group-pinned-user?service_id=${service_id}`
      );
      dispatch(handleType(contractTypes.GET_GROUP_PINNED_USER, response.data));
    } catch (e) {
    }
  };
};

export const deleteContract = (token: any, contract: number | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.post(
        "colocation/delete-contract",
        {contract},
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(handleType(contractTypes.DELETE_CONTRACT, response.data));
    } catch (e) {
    }
  };
};

export const rejectContract = (token: any, comment: string, contract: number | undefined, slug: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.post(
        `${slug}/contract-rejected/${contract}`,
        {comment},
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      dispatch(handleType(contractTypes.DELETE_CONTRACT, response.data));
    } catch (e) {
    }
  };
};

export const getDataCenterList = (token: RefreshState | null) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.GET_DATA_CENTER_LIST_START, null))
      const response = await axios2.get('colocation/list/data-center', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch(handleType(contractTypes.GET_DATA_CENTER_LIST_SUCCESS, response.data))
    } catch (e) {
      dispatch(handleType(contractTypes.GET_DATA_CENTER_LIST_ERROR, e))
    }
  }
}

export const getDataCenterCalculate = (token: RefreshState | null, data: any, check: boolean | undefined) => {
  return async (dispatch: Dispatch) => {
    if (!check) {
      try {
        dispatch(handleType(contractTypes.POST_CALCULATE_COLOCATION_START, null))
        const response = await axios2.post('billing/calculate-colocation', data, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        dispatch(handleType(contractTypes.POST_CALCULATE_COLOCATION_SUCCESS, response.data))
      } catch (e) {
        dispatch(handleType(contractTypes.POST_CALCULATE_COLOCATION_ERROR, e))
      }
    }
  }
}

export const rejectDM = (token: any, slug: string, id: number | undefined, navigate: any, savedDevices: any, savedContractDetails: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(contractTypes.CONTRACTS_REJECT_START, null))
      const response = await axios2.delete(`${slug}/reject/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(contractTypes.CONTRACTS_REJECT_SUCCESS, response.data))
      navigate('/')
      // @ts-ignore
      dispatch(clearDevices(savedDevices));
      // @ts-ignore
      dispatch(clearSavedContractDetails(savedContractDetails));
    } catch (e) {
      dispatch(handleType(contractTypes.CONTRACTS_REJECT_FAILED, e))
    }
  }
}
