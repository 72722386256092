import * as emailActionTypes from '../actionTypes/EMailActionTypes'
import {axios2} from "../../axios";
import {handleType} from "./handleType";
import {Dispatch} from "redux";
import {RefreshState} from "./authActions";

export const createEMailContract = (token: RefreshState | null, data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(emailActionTypes.POST_EMAIL_START, null))
    try {
      const res = await axios2.post('e-xat/contract-create', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      dispatch(handleType(emailActionTypes.POST_EMAIL_SUCCESS, res.data))
      return res.data
    } catch (e: any) {
      dispatch(handleType(emailActionTypes.POST_EMAIL_ERROR, e.message))
      return e
    }
  }
}

export const createEMailLetter = (token: RefreshState | null, data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(emailActionTypes.POST_EMAIL_LETTER_START, null))
    try {
      const res = await axios2.post('e-xat/letter', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      dispatch(handleType(emailActionTypes.POST_EMAIL_LETTER_SUCCESS, res.data))
      return res.data
    } catch (e: any) {
      dispatch(handleType(emailActionTypes.POST_EMAIL_LETTER_ERROR, e.message))
      return e
    }
  }
}

export const fileStore = (token: RefreshState | null, data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(emailActionTypes.FILE_STORE_START, null))
    try {
      const res = await axios2.post('contracts/file-store', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      dispatch(handleType(emailActionTypes.FILE_STORE_SUCCESS, res.data))
      return res.data
    } catch (e: any) {
      dispatch(handleType(emailActionTypes.FILE_STORE_ERROR, e.message))
      return e
    }
  }
}

export const addLotNumber = (data: any, id?: string | number | null) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(emailActionTypes.LOT_ADD_START, null))
    try {
      const response = await axios2.post(`e-xat/enter-lot-number/${id}`, data)
      dispatch(handleType(emailActionTypes.LOT_ADD_SUCCESS, response.data))
      return response.data
    } catch (e: any) {
      dispatch(handleType(emailActionTypes.LOT_ADD_ERROR, e.message))
      return e
    }
  }
}